import { ReactComponent as EmptyIcon } from '../../../images/icons/empty.svg'

const NoImages = () => (
  <div className="img-container-print-order">
    <div className="empty-state-print-order-container">
      <div className="center-div-container">
        <div className="icon big w-embed">
          <EmptyIcon />
        </div>
        <div className="divider" />
        <h2 className="heading-title-2">Your order is empty</h2>
        <div className="divider" />
        <div>Upload one or more files to start</div>
      </div>
    </div>
  </div>
)

export default NoImages
