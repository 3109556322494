import dayjs from 'dayjs'
import Tooltip from '../../common/Tooltip'
import { capitalizeFirstLetter } from '../../../lib/utils'
import { DATE_FORMAT } from '../../../lib/const'

import { ReactComponent as TrackingIcon } from '../../../images/icons/tracking.svg'
import { ReactComponent as NoteIcon } from '../../../images/icons/note.svg'
import { ReactComponent as ShippingIcon } from '../../../images/icons/shipping.svg'

const Header = ({ attributes }) => (
  <div className="order-top-div">
    <div className="container-order-title">
      <div className="grid-details">
        <h3 className="heading-gray">Order #</h3>
        <div className="grid-order-icons">
          <h1 className="heading-big">-</h1>
          <div className={`status-order ${attributes.status}`}>
            {capitalizeFirstLetter(attributes.status)}
          </div>
          <div className="sub-grid-icons-order">
            {attributes.hasNotes && (
              <Tooltip title="Order Note">
                <div className="note tippy">
                  <div className="icon small w-embed">
                    <NoteIcon />
                  </div>
                </div>
              </Tooltip>
            )}
            <Tooltip title="Rush Shipping">
              <div className="rush tippy">
                <div className="icon small w-embed">
                  <ShippingIcon />
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
    <div className="grid-details right">
      <h3 className="heading-mini-gray">Placed on:</h3>
      <h3 className="heading-title-2">{dayjs(attributes.createdAt).format(DATE_FORMAT.full)}</h3>
      <div className="grid-tracking">
        <div className="div-block-135">
          <div className="icon small right-margin w-embed">
            <TrackingIcon />
          </div>
          <div>Tracking Number</div>
        </div>
        <div className="tracking-text">-</div>
      </div>
    </div>
  </div>
)

export default Header
