import { useCallback, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import values from 'lodash/values'
import cn from 'classnames'

import { ReactComponent as AccountIcon } from '../../images/icons/NavDrawer/account.svg'
import { PROFILE_RULES } from '../../lib/const'
import { apiErrorMessage } from '../../lib/utils'
import httpClient from '../../httpClient'

import ErrorMessage from '../common/ErrorMessage'
import AccountHeading from './AccountHeading'
import TextInput from './TextInput'

const BasicInformation = ({ user, onUpdate }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isDirty },
  } = useForm()
  const [apiError, setApiError] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleUpdate = useCallback(async (data) => {
    try {
      setLoading(true)
      const profile = await httpClient.patch(`users/${user?.guid}`, data)
      onUpdate(profile)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setApiError(apiErrorMessage(error))
    }
  }, [])

  return (
    <form
      onSubmit={handleSubmit(handleUpdate)}
      id="address"
      className="address-info-wrap"
      style={{ display: 'block' }}
    >
      <div className="accordion-item-block no-margin">
        <AccountHeading title="Basic Information" icon={AccountIcon} />
        <div className="w-layout-grid grid-form _1fr">
          <Controller
            rules={PROFILE_RULES.firstName}
            name="firstName"
            defaultValue={user?.firstName ?? ''}
            control={control}
            render={({ field }) => (
              <TextInput {...field} label="first name" placeholder="Jonn" name="firstName" />
            )}
          />
          <Controller
            rules={PROFILE_RULES.lastName}
            name="lastName"
            defaultValue={user?.lastName ?? ''}
            control={control}
            render={({ field }) => (
              <TextInput {...field} label="last name" placeholder="Smith" name="lastName" />
            )}
          />
        </div>
        <div className="w-layout-grid grid-form _1fr">
          <Controller
            rules={PROFILE_RULES.businessName}
            name="businessName"
            defaultValue={user?.businessName ?? ''}
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                label="COMPANY NAME"
                placeholder="My Company"
                name="businessName"
              />
            )}
          />
          <Controller
            rules={PROFILE_RULES.phone}
            name="phone"
            defaultValue={user?.phone ?? ''}
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                label="PHONE NUMBER"
                placeholder="(000) - 1234 - 456"
                name="phone"
              />
            )}
          />
          <Controller
            rules={PROFILE_RULES.businessUrl}
            name="businessUrl"
            defaultValue={user?.businessUrl ?? ''}
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                label="WEBSITE ADDRESS"
                placeholder="mywebsite.com"
                name="businessUrl"
              />
            )}
          />

          <div className="button-form-container" style={{ width: '100%' }}>
            <button
              type="submit"
              disabled={isSubmitting || loading || values(errors)[0] || !isDirty}
              className={cn('button-2 secondary w-inline-block', {
                disabled: loading || isSubmitting || values(errors)[0] || !isDirty,
              })}
            >
              <div>{loading || isSubmitting ? 'Please wait...' : 'Update Info'}</div>
            </button>
          </div>
        </div>
      </div>

      {(values(errors)[0] || apiError) && (
        <div className="form-item-2">
          <ErrorMessage errors={values(errors)?.[0]?.message || apiError} />
        </div>
      )}
    </form>
  )
}

export default BasicInformation
