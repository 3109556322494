import { Link, useNavigate } from 'react-router-dom'

import logoImage from '../../images/Green_Musea_Logo.svg'

import Loading from '../common/Loading'
import ThankYouMessage from './ThankYouMessage'
import { useCheckout } from './useCheckout'
import CartSummary from './CheckoutSummary'
import CartDetails from './CheckoutDetails'

const ReviewCart = () => {
  const { loading, processPaymentSuccess, isInvalidOrder } = useCheckout()
  const navigate = useNavigate()

  if (isInvalidOrder) {
    return navigate('/')
  }

  if (loading) {
    return <Loading message="Loading order" />
  }

  if (processPaymentSuccess) {
    return <ThankYouMessage />
  }

  return (
    <div style={{ opacity: 1, display: 'flex' }} className="review-cart-container">
      <div className="top-div-container-cart">
        <div className="sub-container-top-cart">
          <Link to="/" className="brand-2 w-nav-brand">
            <img src={logoImage} loading="lazy" alt="" className="image-2-copy" />
            <div className="text-block-copy">
              <span className="text-span-2">MUSEA</span>
              {' Lab'}
            </div>
          </Link>
        </div>
      </div>
      <div className="w-layout-grid grid-review-cart">
        <CartDetails />
        <CartSummary />
      </div>
    </div>
  )
}

export default ReviewCart
