import { useCallback, useEffect } from 'react'
import cn from 'classnames'
import { useForm, Controller } from 'react-hook-form'

import { useToggle } from '../../../hooks'
import { LAB_ORDER_ATTRIBUTES } from '../../../lib/const'

import ErrorMessage from '../../common/ErrorMessage'
import { useCheckout } from '../useCheckout'
// import { ReactComponent as ChevronDownIcon } from 'images/icons/chevron-down.svg'

const MAX_NOTE_LENGHT = 300

const Boutique = () => {
  const { order, updateOrder } = useCheckout()
  const [showBoutique, toggleShowBoutique] = useToggle(order?.data?.attributes?.boutiqueShipping)
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm()
  const watchBoutiqueShippingNote = watch('boutiqueShippingNote')
  const allowSaveNote =
    watchBoutiqueShippingNote &&
    watchBoutiqueShippingNote !== order?.data?.attributes?.boutiqueShippingNote

  const handleUpdateBoutique = useCallback(
    ({ boutiqueShippingNote }) =>
      updateOrder({
        type: LAB_ORDER_ATTRIBUTES.boutiqueShipping,
        value: true,
        boutiqueShippingNote,
      }),
    [order],
  )

  useEffect(() => {
    if (!showBoutique) {
      updateOrder({
        type: LAB_ORDER_ATTRIBUTES.boutiqueShipping,
        value: false,
        boutiqueShippingNote: '',
      })
    }
  }, [showBoutique])

  return (
    <div data-ix="fade-up-1" className="accordion js-accordion margin-top">
      <div className="accordion__item js-accordion-item">
        <div className="accordion-header js-accordion-header">
          <div className="accordion-item no-padding">
            <div className="form-block-11 w-form">
              <div id="email-form-3" name="email-form-3" data-name="Email Form 3">
                <div>
                  <label id="check" className="w-checkbox checkbox-field-4">
                    <div
                      className={cn(
                        'w-checkbox-input w-checkbox-input--inputType-custom check secondary',
                        {
                          'w--redirected-checked': showBoutique,
                        },
                      )}
                    />
                    <input
                      type="checkbox"
                      onChange={toggleShowBoutique}
                      style={{ opacity: 0, position: 'absolute', zIndex: '-1' }}
                    />
                    <span htmlFor="check1" id="check-label" className="w-form-label">
                      Add Boutique Packaging
                    </span>
                  </label>
                </div>
              </div>
            </div>
            {/* <ChevronDownIcon className="img-icon margin-left" /> */}
          </div>
        </div>
        {showBoutique && (
          <form
            className="accordion-body js-accordion-body"
            onSubmit={handleSubmit(handleUpdateBoutique)}
          >
            <div id="show-boutique" className="accordion-item-content">
              <div className="form-block-input margin-top w-form">
                <div className="form-input select-field area">
                  <div className="select-field-wrapper area">
                    <Controller
                      rules={{
                        required: true,
                        maxLength: {
                          value: MAX_NOTE_LENGHT,
                          message: `Max lengh is ${MAX_NOTE_LENGHT}.`,
                        },
                      }}
                      name="boutiqueShippingNote"
                      control={control}
                      defaultValue={order?.data?.attributes?.boutiqueShippingNote}
                      render={({ field }) => (
                        <textarea
                          {...field}
                          placeholder="Write a note for your client..."
                          maxLength={MAX_NOTE_LENGHT}
                          className="text-field-2 font-size-14 area w-input"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className={cn('button secondary margin-top w-inline-block', {
                  disabled: isSubmitting || !allowSaveNote,
                })}
                disabled={isSubmitting || !allowSaveNote}
              >
                <div>Save Boutique Note</div>
              </button>
              {errors?.boutiqueShippingNote?.message && (
                <ErrorMessage errors={errors?.boutiqueShippingNote?.message} />
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default Boutique
