import { ReactComponent as DownloadIcon } from '../../../images/icons/download.svg'
import { ReactComponent as CircleIcon } from '../../../images/icons/circle.svg'

const SalesHistoryItem = ({ link, year }) => (
  <div className="w-layout-grid grid-23">
    <div
      id="w-node-_66260a0b-5f09-08cc-fedf-d8ea3690ffe9-0ce80a13"
      className="icon smaller right-margin supermini w-embed"
    >
      <CircleIcon />
    </div>
    <div>
      <strong>{year}</strong>
      {' Yearly Sales'}
    </div>
    <div className="mini-button-gray">
      <div className="icon smaller w-embed">
        <a href={link} className="icon smaller w-embed" style={{ color: '#ffffff' }}>
          <DownloadIcon />
        </a>
      </div>
    </div>
  </div>
)

export default SalesHistoryItem
