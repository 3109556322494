import React, { createContext, useMemo, useContext, useEffect, useState, useCallback } from 'react'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import { isEmpty } from 'lodash'

import httpClient from '../../httpClient'
import useProfile from '../../hooks/useProfile'
import { apiErrorMessage } from '../../lib/utils'
import { LAB_ORDER_ATTRIBUTES, LAB_ORDER_STATUS } from '../../lib/const'

const CheckoutContext = createContext(null)

const CheckoutProvider = ({ children }) => {
  const location = useLocation()
  const { profile, error: profileError, onProfileUpdate } = useProfile()
  const [order, setOrder] = useState(null)
  const [loading, setLoading] = useState(true)
  const [apiError, setApiError] = useState(null)
  const [isInvalidOrder, setInvalidOrder] = useState(false)

  const [processPaymentSuccess, setProcessPaymentSuccess] = useState(
    order?.data?.attributes?.status === LAB_ORDER_STATUS.paid,
  )
  const queryParams = queryString.parse(location.search)
  const canSubmitOrder =
    // order?.data?.attributes?.clientName &&
    (!isEmpty(order?.data?.attributes?.shippingAddress) || order?.data?.attributes?.localPickup) &&
    order?.data?.attributes?.selectedCardToUse

  useEffect(() => {
    if (order?.data?.attributes?.status === LAB_ORDER_STATUS.paid) {
      setProcessPaymentSuccess(true)
    }
  }, [order])

  useEffect(() => {
    if (!queryParams?.order) {
      setInvalidOrder(true)
    }
  }, [queryParams])

  const getOrder = async () => {
    try {
      const data = await httpClient(`lab_orders/${queryParams.order}`)
      setOrder(data)
    } catch (error) {
      setInvalidOrder(true)
    }
  }

  useEffect(() => {
    (async () => {
      setLoading(true)
      await getOrder()
      setLoading(false)
    })()
  }, [])

  const updateOrder = useCallback(async (data) => {
    setApiError(null)
    try {
      const res = await httpClient.patch(`lab_orders/${queryParams.order}`, data)
      setOrder(res)
    } catch (error) {
      setApiError(apiErrorMessage(error))
    }
  }, [])

  const applyDiscount = useCallback(async (data) => {
    setApiError(null)
    try {
      await httpClient.post('/validate_discount_code', { code: data.value })
      const res = await httpClient.patch(`lab_orders/${queryParams.order}`, data)
      setOrder(res)
    } catch (error) {
      setApiError(apiErrorMessage(error))
    }
  }, [])

  const addAddress = useCallback(async (data) => {
    try {
      const res = await httpClient.post('/addresses', data)
      const { id } = res.addresses.data.find(
        ({ attributes }) =>
          attributes.street === data.streetAddress &&
          attributes.city === data.city &&
          attributes.stateProvince === data.state &&
          attributes.zip === data.zip,
      )
      onProfileUpdate(res)
      await updateOrder({
        type: LAB_ORDER_ATTRIBUTES.localPickup,
        value: false,
      })
      await updateOrder({
        type: LAB_ORDER_ATTRIBUTES.shippingAddressId,
        value: id,
      })
    } catch (error) {
      setApiError(apiErrorMessage(error))
    }
  }, [])

  const processPayment = useCallback(async () => {
    try {
      await httpClient.post(`lab_orders/${order?.data?.attributes?.guid}/checkout`)
      setProcessPaymentSuccess(true)
    } catch (error) {
      setApiError(apiErrorMessage(error))
    }
  }, [order])

  const value = useMemo(
    () => ({
      loading,
      error: apiError || profileError,
      isInvalidOrder,
      canSubmitOrder,
      processPaymentSuccess,
      profile,
      order,
      applyDiscount,
      addAddress,
      updateOrder,
      processPayment,
    }),
    [loading, apiError, isInvalidOrder, processPaymentSuccess, profile, order],
  )

  return <CheckoutContext.Provider value={value}>{children}</CheckoutContext.Provider>
}

const useCheckout = () => {
  const context = useContext(CheckoutContext)

  if (!context) {
    throw new Error('useCheckout should be inside CheckoutProvider')
  }

  return context
}

export { CheckoutProvider, useCheckout }
