import { useState, useCallback } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import httpClient from '../../../../httpClient'

// import { ReactComponent as CreditCardIcon } from 'images/icons/credit-card.svg'
import { ReactComponent as AlertIcon } from '../../../../images/icons/alert.svg'
import { useCheckout } from '../../useCheckout'
import { apiErrorMessage } from '../../../../lib/utils'

const AddCreditCard = () => {
  const stripe = useStripe()
  const elements = useElements()
  const { order } = useCheckout()
  const [, setLoading] = useState(false)
  const [apiError, setApiError] = useState(null)
  const [stripeError, setStripeError] = useState(null)

  const handleOnChange = useCallback(async (e) => {
    setLoading(false)
    if (e.complete) {
      setLoading(true)
      setApiError(null)
      setStripeError(null)

      if (!stripe || !elements) {
        return
      }

      const cardElement = elements.getElement(CardElement)

      try {
        const { error, token } = await stripe.createToken(cardElement)
        if (error) {
          setStripeError(error.message)
          return
        }

        await httpClient.post('/accounts/create_card', {
          tokenId: token.id,
          labOrderId: order?.data?.attributes?.guid,
        })
      } catch (error) {
        setApiError(apiErrorMessage(error))
      }
    }
  }, [])

  return (
    <div id="credit-show" className="card-info-wrapper" style={{ display: 'block' }}>
      <div className="input-container paper-type">
        <div className="label-container">
          <div className="label-subcontainer">
            <div className="sub-label-form">card information</div>
          </div>
        </div>
        <div className="form-block-input w-form">
          <div
            className="form-input select-field"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingLeft: '5px',
              paddingRight: '10px',
            }}
          >
            {/* <div className="select-field-wrapper credit-card">
              <div className="div-block-223">
                <div className="icon small w-embed">
                  <CreditCardIcon />
                </div>
              </div>
              <div className="w-embed">
                <input
                  className="text-field-2 font-size-14 spaced"
                  id="cc"
                  value=""
                  placeholder="0000 0000 0000 0000"
                />
              </div>
              <input
                type="text"
                className="text-field-2 font-size-14 spaced w-input"
                maxLength="256"
                name="field-4"
                data-name="Field 4"
                placeholder="MM/YY"
                id="data-jscolor"
              />
              <input
                type="text"
                className="text-field-2 font-size-14
                spaced w-node-_80ce2b53-38cf-7fb5-7af3-2d9aebebda24-5683c88d w-input"
                maxLength="256"
                name="field-4"
                data-name="Field 4"
                placeholder="CVC"
                id="data-jscolor"
              />
            </div> */}
            <CardElement options={{ hidePostalCode: true }} onChange={handleOnChange} disabled />
          </div>
        </div>
        {stripeError ||
          (apiError && (
            <div className="div-block-246">
              <div className="label-subcontainer declined">
                <div className="icon mini w-embed">
                  <AlertIcon />
                </div>
                <div>{stripeError}</div>
                <div>{apiError}</div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default AddCreditCard
