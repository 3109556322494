import { useCallback } from 'react'
import debounce from 'lodash/debounce'
import { DEFAULT_DEBOUNCE } from '../../../lib/const'
import { useCheckout } from '../useCheckout'

const inputs = [
  { label: 'First Name', attribute: 'clientName' },
  { label: 'Last Name', attribute: 'clientName' },
]

const ClientName = () => {
  const { order, updateOrder } = useCheckout()
  const clientName = order?.attributes?.clientName

  const handleUpdate = useCallback(
    () =>
      debounce(
        (e) =>
          updateOrder({
            [e.target.name]: e.target.value === '' ? null : e.target.value,
          }),
        DEFAULT_DEBOUNCE,
      ),
    [clientName],
  )

  return (
    <div className="w-layout-grid grid-form _1fr">
      {inputs.map((input) => (
        <div
          id="w-node-_266adb6c-012a-0af0-e9f2-608c53dcb7d1-5683c88d"
          className="input-container paper-type"
          key={input.label}
        >
          <div className="label-container">
            <div className="label-subcontainer">
              <div className="sub-label-form">{input.label}</div>
            </div>
          </div>
          <div className="form-block-input w-form">
            <div
              id="email-form"
              name="email-form"
              data-name="Email Form"
              className="form-input select-field"
            >
              <div className="select-field-wrapper">
                <input
                  type="text"
                  className="text-field-2 font-size-14 w-input"
                  maxLength="256"
                  name={input.attribute}
                  defaultValue={clientName}
                  placeholder={input.label}
                  onChange={handleUpdate(input.attribute)}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ClientName
