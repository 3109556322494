import { ReactComponent as FilterIcon } from '../../../images/icons/filter.svg'
import { ReactComponent as ChevronDownIcon } from '../../../images/icons/chevron-down.svg'

const PhotographerSales = () => (
  <div className="w-layout-grid grid-sales">
    <div className="container-sales ranking">
      <div id="w-node-e418690d-dbc5-cf5f-c30d-91fe8bceff96-41e80a1e">
        <h2 className="sub-label-form grey no-margin">Total Sales</h2>
        <h2 className="heading-title-2">$ 1420.00</h2>
      </div>
      <div className="raking-div">
        <div className="ranking-circle">
          <div>#10</div>
        </div>
      </div>
    </div>
    <div className="container-sales">
      <h2 className="sub-label-form grey no-margin">Total credit</h2>
      <div className="form-block-17 w-form">
        <form id="email-form-2" name="email-form-2" data-name="Email Form 2" className="form-10">
          <h2 className="heading-title-2">$</h2>
          <input
            type="number"
            className="heading-title-2 w-input"
            maxLength="256"
            name="0.00"
            data-name="0.00"
            placeholder="0.00"
            id="0.-2"
          />
        </form>
      </div>
    </div>
    <div className="input-container">
      <div className="form-block-16 w-form">
        <form
          id="email-form"
          name="email-form"
          data-name="Email Form"
          className="form-input select-field _2nd-color"
        >
          <div className="select-field-wrapper select">
            <div className="icon small x2 top-margin w-embed">
              <FilterIcon />
            </div>
            <select
              id="save"
              name="field-3"
              data-name="Field 3"
              className="form-select-field secondary w-select"
            >
              <option value="Filter by Year to date">Filter by Year to date</option>
              <option value="Filter by Last 6 Months">Filter by Last 6 Months</option>
              <option value="Filter by Last 90 days">Filter by Last 90 days</option>
              <option value="Filter by Last month">Filter by Last month</option>
              <option value="Filter by Yesterday">Filter by Yesterday</option>
              <option value="Filter by Today">Filter by Today</option>
              <option value="Filter by All time sales">Filter by All time sales</option>
            </select>
            <div className="div-block-63">
              <ChevronDownIcon className="img-icon" />
            </div>
          </div>
        </form>
      </div>
    </div>
    <a
      href="/"
      id="w-node-_01b56de3-15e9-bb3c-3794-0cfe1bc04585-41e80a1e"
      className="button-2 secondary w-inline-block"
    >
      <div>Save New Credit</div>
    </a>
  </div>
)

export default PhotographerSales
