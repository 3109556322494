/* eslint-disable no-unused-vars */
import { memo, useRef, useState, useEffect } from 'react';

const Modal = ({ isOpen, onClose, children }) => {
  const modalOverlayRef = useRef(null);

  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     // Check if the clicked element is the modal overlay (not its children)
  //     if (modalOverlayRef.current && !modalOverlayRef.current.contains(event.target)) {
  //       onClose();
  //     }
  //   };

  //   if (!isOpen) {
  //     document.addEventListener('click', handleOutsideClick);
  //   }

  //   // Remove the event listener when the component unmounts or modal closes
  //   return () => {
  //     document.removeEventListener('click', handleOutsideClick);
  //   };
  // }, [isOpen]);

  const modalOverlayStyle = {
    position: 'fixed',
    zIndex: 9999999,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: isOpen ? 1 : 0,
    transition: 'opacity 0.3s ease',
    pointerEvents: isOpen ? 'auto' : 'none',
  };

  const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    maxWidth: '500px',
    fontWeight: 'normal',
    transform: isOpen ? 'translateY(0)' : 'translateY(-50px)',
    transition: 'all 0.3s ease-in-out',
  };

  const modalCloseButtonStyle = {
    display: 'block',
    width: '100px',
    marginTop: '20px',
    marginLeft: 'auto',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    padding: '8px 16px',
    cursor: 'pointer',
    fontWeight: 'bold',
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    // no propogation of click events to children
    <div style={modalOverlayStyle} ref={modalOverlayRef}>
      <div style={modalContentStyle}>
        {children}
        <button type="button" style={modalCloseButtonStyle} onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  )
}

export default memo(Modal);
