import { useState, useEffect, useMemo } from 'react'

import httpClient from '../httpClient'
import { apiErrorMessage } from '../lib/utils'

const usePhotographers = () => {
  const [photographers, setPhotographers] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchPhotographers = async () => {
    try {
      setLoading(true)
      const ph = await httpClient('photographers')
      setPhotographers(ph)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setError(apiErrorMessage(err))
    }
  }

  useEffect(() => {
    fetchPhotographers()
  }, [])

  return useMemo(
    () => ({
      photographers,
      error,
      loading,
    }),
    [photographers, error, loading],
  )
}

export default usePhotographers
