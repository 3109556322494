import { useCallback, memo } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

import { useLottie, useDeleteModal } from '../../hooks'
import animationData from '../../animations/openOrder.json'
import { capitalizeFirstLetter } from '../../lib/utils'
import { DATE_FORMAT, PAPER_TYPES, SERVICE_TYPES, SHIPPING_METHODS } from '../../lib/const'

import placeholder from '../../images/placeholder.jpeg'
import { ReactComponent as UrgentIcon } from '../../images/icons/urgent.svg'
import { ReactComponent as NoteIcon } from '../../images/icons/note.svg'
import { ReactComponent as DeleteIcon } from '../../images/icons/orderDelete.svg'
import { ReactComponent as ShippingIcon } from '../../images/icons/shipping.svg'
import Tooltip from '../common/Tooltip'
import PaperTypeIcon from '../common/PaperTypeIcon'
import ServiceIcon from '../common/ServiceIcon'

const lottieOptions = {
  animationData,
  loop: true,
  autoplay: true,
  renderer: 'svg',
  direction: 1,
  duration: 0,
}

const LabOrderCard = ({ order, onDelete, isAdmin }) => {
  const [, container] = useLottie(lottieOptions)
  const { Modal, setIsOpen } = useDeleteModal()
  const { attributes } = order

  const openModal = useCallback(
    (e) => {
      e.preventDefault()
      setIsOpen(true)
    },
    [setIsOpen],
  )

  const handleDelete = useCallback(
    (e) => {
      e.preventDefault()
      onDelete(attributes.id)
    },
    [order, onDelete],
  )

  return (
    <>
      <div className="order-card">
        <div className="container-picture-card">
          <img src={attributes.coverPhotoUrl ?? placeholder} alt="" className="image-5" />
          <Link to={`/lab-orders/${order.id}`} className="link-card w-inline-block">
            <div className="w-layout-grid grid-7">
              <div
                ref={container}
                style={{ transform: 'initial', width: 26, height: 26 }}
              />
              <div>Open Now</div>
            </div>
            <div data-w-id="d30cf4d6-488c-cec3-3153-040985f7e223" className="div-block-67">
              <div
                className="icon w-embed"
                onClick={openModal}
                onKeyPress={openModal}
                role="button"
              >
                <DeleteIcon />
              </div>
            </div>
          </Link>
          <div className="w-layout-grid grid-16">
            {attributes.urgent && (
              <Tooltip title="Urgent">
                <div className="urgent tippy">
                  <div className="icon small w-embed">
                    <UrgentIcon />
                  </div>
                </div>
              </Tooltip>
            )}
            {attributes.hasNotes && (
              <Tooltip title="Order Note">
                <div className="note tippy">
                  <div className="icon small w-embed">
                    <NoteIcon />
                  </div>
                </div>
              </Tooltip>
            )}
            {isAdmin && attributes.hasAdminNotes && (
              <Tooltip title="Admin Note">
                <div className="note tippy">
                  <div className="icon small w-embed">
                    <NoteIcon />
                  </div>
                  <div className="div-block-56">
                    <div>A</div>
                  </div>
                </div>
              </Tooltip>
            )}

            {isAdmin &&
              (attributes.shippingMethod === SHIPPING_METHODS.twoDay ||
                attributes.shippingMethod === SHIPPING_METHODS.overnight) && (
                <Tooltip title="Short Run">
                  <div className="rush tippy">
                    <div className="icon small w-embed">
                      <ShippingIcon />
                    </div>
                  </div>
                </Tooltip>
                // eslint-disable-next-line indent
              )}
          </div>
        </div>
        <div className="product-detail-div">
          <div className="div-block-15">
            <div className="div-title-card">
              <div>
                <div className="title-info-card">{attributes.orderName ?? '-'}</div>
              </div>
              <div className={`toggle-status ${attributes.status}`}>
                <div>{capitalizeFirstLetter(attributes.status)}</div>
              </div>
            </div>
            <div className="div-title-card">
              <div>
                {isAdmin ? (
                  <a
                    href={`/photographers/${attributes.userId}`}
                    className="link-text w-inline-block"
                  >
                    <div>{attributes.photographerName ?? '-'}</div>
                  </a>
                ) : (
                  <div>{attributes.photographerName ?? '-'}</div>
                )}
              </div>
              {
                // TODO: ADD MISSING PAPER TYPES IN PAPER_TYPES
              }
              {isAdmin && (
                <div className="w-layout-grid grid-15">
                  {attributes?.papers?.map(
                    (paper) =>
                      PAPER_TYPES[paper] && (
                        <Tooltip key={paper} title={PAPER_TYPES[paper].label}>
                          <PaperTypeIcon value={PAPER_TYPES[paper].abbreviation} />
                        </Tooltip>
                      ),
                  )}
                </div>
              )}
            </div>
            <div>{attributes.photographerBusiness ?? '-'}</div>
          </div>
          <div className="divider" />
          <div className="div-sub-info-card">
            <div className="w-layout-grid grid-6 date-div">
              <div>{dayjs(attributes.createdAt).format(DATE_FORMAT.monthDayYear)}</div>
            </div>
            <div className="w-layout-grid grid-15">
              {attributes?.allServices?.map(
                (service) =>
                  SERVICE_TYPES[service] && (
                    <Tooltip key={service} title={SERVICE_TYPES[service].label}>
                      <ServiceIcon
                        classname={SERVICE_TYPES[service].iconClassname}
                        value={SERVICE_TYPES[service].abbreviation}
                      />
                    </Tooltip>
                  ),
              )}
            </div>
          </div>
          <div className="w-layout-grid grid-progress-bar tippy">
            <Tooltip title="Progress">
              <div className="progress-bar">
                <div className={`bar _${attributes.progress}`} />
              </div>
            </Tooltip>
            <div id="w-node-b85c4af7-f736-7b47-03da-55b12a957c10-2283c889">{`${attributes.progress}%`}</div>
          </div>
        </div>
      </div>
      <Modal
        title="Deleting Item"
        description="You want to remove this item. This action will not be undone and cannot be reversed."
        onContinue={handleDelete}
        icon={DeleteIcon}
      />
    </>
  )
}

export default memo(LabOrderCard)
