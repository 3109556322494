import { useState, useCallback, useEffect } from 'react'

import { copyToClipboard } from '../../../lib/utils'

import { ReactComponent as CopyIcon } from '../../../images/icons/copy.svg'
import { ReactComponent as VisibilityIcon } from '../../../images/icons/visibility.svg'
import { ReactComponent as CheckIcon } from '../../../images/icons/accept.svg'
import Tooltip from '../../common/Tooltip'

const PhotographerPasswordCopy = () => {
  const [hiddenPassword, setHiddenPassword] = useState(true)
  const [copiedToClipboard, setCopiedToClipboard] = useState(false)

  const handleToogleVisibility = useCallback(() => setHiddenPassword((prev) => !prev), [])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (copiedToClipboard) {
      const interval = setInterval(() => {
        setCopiedToClipboard(false)
      }, 3000)
      return () => clearInterval(interval)
    }
  }, [copiedToClipboard])

  return (
    <>
      <div
        id="w-node-f6db6d51-cc6f-2564-c876-ff83d387b32e-41e80a1e"
        className="input-container paper-type"
      >
        <div className="label-container">
          <div className="div-block-150">
            <div className="sub-label-form">Current password</div>
          </div>
        </div>
        <div className="form-block-input w-form">
          <form
            id="email-form"
            name="email-form"
            data-name="Email Form"
            className="form-input select-field"
          >
            <div className="select-field-wrapper">
              <input
                type={hiddenPassword ? 'password' : 'text'}
                className="text-field-2 font-size-14 w-input"
                maxLength="256"
                name="field-4"
                data-name="Field 4"
                id="data-jscolor"
                disabled
                defaultValue="password"
              />
              <div className="div-block-289">
                <div
                  role="button"
                  onClick={handleToogleVisibility}
                  onKeyPress={handleToogleVisibility}
                  className="icon small w-embed"
                >
                  <VisibilityIcon />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        id="w-node-f6db6d51-cc6f-2564-c876-ff83d387b34c-41e80a1e"
        className="button-form-container"
      >
        <button type="button" className="button-2 secondary single w-inline-block">
          <Tooltip title="Copy to clipboard">
            <div
              role="button"
              className="icon small w-embed"
              onClick={() => {
                copyToClipboard('password')
                setCopiedToClipboard(true)
              }}
            >
              {copiedToClipboard ? <CheckIcon /> : <CopyIcon />}
            </div>
          </Tooltip>
        </button>
      </div>
    </>
  )
}

export default PhotographerPasswordCopy
