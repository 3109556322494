import { ReactComponent as CloseIcon } from '../../images/icons/close.svg'

const Filter = ({ label, onDelete }) => (
  <div className="filter-spec-div">
    <div>{label}</div>
    <div
      role="button"
      className="icon smaller left-margin w-embed"
      onClick={onDelete}
      onKeyPress={onDelete}
    >
      <CloseIcon />
    </div>
  </div>
)

export default Filter
