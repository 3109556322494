import { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router'

import httpClient from '../httpClient'
import { apiErrorMessage } from '../lib/utils'

import Loading from '../components/common/Loading'
import OrderSummary from '../components/LabOrderSummary/OrderSummary'
import OrderCheckout from '../components/LabOrderSummary/OrderCheckout'

const LabOrderSummaryPage = () => {
  const { id } = useParams()
  const [order, setOrder] = useState(null)
  const [loading, setLoading] = useState(false)
  const [apiError, setApiError] = useState(false)

  useEffect(() => {
    (async () => {
      setLoading(true)
      try {
        const data = await httpClient(`lab_orders/${id}`)
        setOrder(data)
        setLoading(false)
      } catch (error) {
        setApiError(apiErrorMessage(error))
        setLoading(false)
      }
    })()
  }, [id])

  const handleUpdateQuantity = useCallback(
    async (guid, quantity) => {
      try {
        const res = await httpClient.patch(`line_items/${guid}/update_quantity`, {
          line_items: [{ quantity }],
        })
        setOrder(res)
      } catch (error) {
        setApiError(apiErrorMessage(error))
      }
    },
    [order],
  )

  const handleDelete = useCallback(
    async (guid) => {
      setLoading(true)
      try {
        const res = await httpClient.delete(`line_items/${guid}`)
        setOrder(res)
        setLoading(false)
      } catch (error) {
        setApiError(apiErrorMessage(error))
        setLoading(false)
      }
    },
    [order],
  )

  if (loading) {
    return <Loading message="Loading order" />
  }

  if (!id || apiError || !order) {
    return null
  }

  return (
    <div className="container-order-review">
      <div className="w-layout-grid grid-67">
        <OrderSummary
          lineItems={order?.included}
          onUpdateQty={handleUpdateQuantity}
          onDelete={handleDelete}
        />
        <OrderCheckout order={order?.data} />
      </div>
    </div>
  )
}

export default LabOrderSummaryPage
