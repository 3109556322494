import { ReactComponent as VisibilityIcon } from '../../../../images/icons/visibility.svg'

const PhotographerOrdersSummaryTable = () => (
  <>
    <div className="topbar-table-gray-mini">
      <div className="w-layout-grid grid-element-photographer-profile header">
        <div id="w-node-edb36a92-3594-4788-3cce-c3be0c59a148-41e80a1e" className="content">
          <div>Order #</div>
        </div>
        <div className="content center">
          <div>Paper Type/Finishing</div>
        </div>
        <div className="content center">
          <div>Status</div>
        </div>
        <div className="content center">
          <div>Date</div>
        </div>
        <div className="content center">
          <div>Total</div>
        </div>
        <div className="content center">
          <div>View</div>
        </div>
      </div>
    </div>
    <div className="w-layout-grid summary-table-photographer-profile">
      <div className="w-layout-grid grid-element-photographer-profile">
        <div className="content">
          <div>#241240</div>
        </div>
        <div className="content center">
          <div className="w-layout-grid grid-36">
            <div className="w-layout-grid grid-15">
              <div data-tippy-content="mounting" className="dot bigger mounting tippy">
                <div>MNT</div>
              </div>
              <div data-tippy-content="Test Print" className="dot bigger test-prints tippy">
                <div>TP</div>
              </div>
              <div data-tippy-content="Boutique" className="dot bigger boutique tippy">
                <div>BTQ</div>
              </div>
            </div>
            <div className="w-layout-grid grid-15">
              <div data-tippy-content="Ultra Smooth" className="dot bigger paper tippy">
                <div>US</div>
              </div>
              <div data-tippy-content="Canson Rag" className="dot bigger paper tippy">
                <div>CR</div>
              </div>
              <div data-tippy-content="Lustre" className="dot bigger paper tippy">
                <div>L</div>
              </div>
            </div>
          </div>
        </div>
        <div className="content center">
          <div className="status-table paid">
            <div>Paid</div>
          </div>
        </div>
        <div className="content center">
          <div>21/02/2021</div>
        </div>
        <div className="content center">
          <div>$ 320.00</div>
        </div>
        <div className="content center">
          <div className="div-block-285">
            <div className="icon mini no-margin w-embed">
              <VisibilityIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default PhotographerOrdersSummaryTable
