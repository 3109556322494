import './styles.css'

const Product = () => (
  <div className="main-wrapper scroll">
    <div className="w-layout-grid navbar-wrapper">
      <div className="w-layout-grid top-grid">
        <div id="w-node-fbfc016e-dba6-8fbd-9065-930ed740fcea-b2bda996">
          <h3 className="heading-3">Create a Product Template</h3>
        </div>
        <nav id="w-node-fc155a37-176e-cb12-a365-67256bc46636-b2bda996" className="breadcrumb">
          <a href="/" className="breadcrumb-link">
            Products
          </a>
          <div className="breadcrumb-divider" />
          <a href="/" className="breadcrumb-link">
            Home &amp; Living
          </a>
          <div className="breadcrumb-divider" />
          <a href="/product" aria-current="page" className="breadcrumb-link w--current">
            Framed Posters
          </a>
        </nav>
      </div>
      <div className="w-layout-grid top-grid">
        <div className="grid-container-controls">
          <form action="/search" className="search-3 w-form">
            <input type="submit" value="." className="search-button-2 w-button" />
            <input
              type="search"
              className="input-2 w-input"
              maxLength="256"
              name="query"
              placeholder="Search by Order Name or Status..."
              id="search"
              required=""
            />
          </form>
          <div className="w-layout-grid grid-filters">
            <div
              data-hover="false"
              data-delay="0"
              id="w-node-_4964c77a-cd44-1f98-8ba6-d9fde9d5b74e-b2bda996"
              className="w-dropdown"
            >
              <div
                className="filter-select-field w-dropdown-toggle"
                id="w-dropdown-toggle-1"
                aria-controls="w-dropdown-list-1"
                aria-haspopup="menu"
                aria-expanded="false"
                role="button"
                tabIndex="0"
              >
                <div>Special Offer</div>
                <div className="icon-4 w-icon-dropdown-toggle" aria-hidden="true" />
              </div>
              <nav
                className="dropdown-list w-dropdown-list"
                id="w-dropdown-list-1"
                aria-labelledby="w-dropdown-toggle-1"
              >
                <div className="form-block-filter w-form">
                  <form
                    id="email-form-3"
                    name="email-form-3"
                    data-name="Email Form 3"
                    className="form-3"
                  >
                    <div className="div-block-95">
                      <label className="w-checkbox checkbox-field-2">
                        <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-3" />
                        <input
                          type="checkbox"
                          id="checkbox-7"
                          name="checkbox-7"
                          data-name="Checkbox 7"
                          style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                        />
                        <span className="w-form-label">Bulk Discount</span>
                      </label>
                    </div>
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
                <div className="container-info-filter bg">
                  <div className="icon-3 small right-margin x2 w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none" />
                      <polyline
                        points="79.833 99.716 31.833 99.716 31.833 51.716"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                      <path
                        d="M65.7746,190.2254a88,88,0,1,0,0-124.4508L31.83348,99.71573"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>Reset All</div>
                </div>
              </nav>
            </div>
            <div className="grid-vertical-line" />
            <div data-hover="false" data-delay="0" className="w-dropdown">
              <div
                className="filter-select-field w-dropdown-toggle"
                id="w-dropdown-toggle-2"
                aria-controls="w-dropdown-list-2"
                aria-haspopup="menu"
                aria-expanded="false"
                role="button"
                tabIndex="0"
              >
                <div>Color</div>
                <div className="icon-4 w-icon-dropdown-toggle" aria-hidden="true" />
              </div>
              <nav
                className="dropdown-list w-dropdown-list"
                id="w-dropdown-list-2"
                aria-labelledby="w-dropdown-toggle-2"
              >
                <div className="form-block-filter w-form">
                  <form
                    id="email-form-3"
                    name="email-form-3"
                    data-name="Email Form 3"
                    className="form-3"
                  >
                    <div className="div-block-95">
                      <label className="w-checkbox checkbox-field-2">
                        <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-3" />
                        <input
                          type="checkbox"
                          id="checkbox-7"
                          name="checkbox-7"
                          data-name="Checkbox 7"
                          style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                        />
                        <span className="w-form-label">Black</span>
                      </label>
                    </div>
                    <div className="div-block-95">
                      <label className="w-checkbox checkbox-field-2">
                        <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-3" />
                        <input
                          type="checkbox"
                          id="checkbox-7"
                          name="checkbox-7"
                          data-name="Checkbox 7"
                          style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                        />
                        <span className="w-form-label">White</span>
                      </label>
                    </div>
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
                <div className="container-info-filter bg">
                  <div className="icon-3 small right-margin x2 w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none" />
                      <polyline
                        points="79.833 99.716 31.833 99.716 31.833 51.716"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                      <path
                        d="M65.7746,190.2254a88,88,0,1,0,0-124.4508L31.83348,99.71573"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>Reset All</div>
                </div>
              </nav>
            </div>
            <div data-hover="false" data-delay="0" className="w-dropdown">
              <div
                className="filter-select-field w-dropdown-toggle"
                id="w-dropdown-toggle-3"
                aria-controls="w-dropdown-list-3"
                aria-haspopup="menu"
                aria-expanded="false"
                role="button"
                tabIndex="0"
              >
                <div>Material</div>
                <div className="icon-4 w-icon-dropdown-toggle" aria-hidden="true" />
              </div>
              <nav
                className="dropdown-list paper-type w-dropdown-list"
                id="w-dropdown-list-3"
                aria-labelledby="w-dropdown-toggle-3"
              >
                <div className="form-block-filter w-form">
                  <form
                    id="email-form-3"
                    name="email-form-3"
                    data-name="Email Form 3"
                    className="form-3"
                  >
                    <div className="div-block-95">
                      <label className="w-checkbox checkbox-field-2">
                        <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-3" />
                        <input
                          type="checkbox"
                          id="checkbox-7"
                          name="checkbox-7"
                          data-name="Checkbox 7"
                          style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                        />
                        <span className="w-form-label">Enhanced Matte Paper</span>
                      </label>
                    </div>
                    <div className="div-block-95">
                      <label className="w-checkbox checkbox-field-2">
                        <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-3" />
                        <input
                          type="checkbox"
                          id="checkbox-7"
                          name="checkbox-7"
                          data-name="Checkbox 7"
                          style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                        />
                        <span className="w-form-label">Premium Luster Paper</span>
                      </label>
                    </div>
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
                <div className="container-info-filter bg">
                  <div className="icon-3 small right-margin x2 w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none" />
                      <polyline
                        points="79.833 99.716 31.833 99.716 31.833 51.716"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                      <path
                        d="M65.7746,190.2254a88,88,0,1,0,0-124.4508L31.83348,99.71573"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>Reset All</div>
                </div>
              </nav>
            </div>
            <div className="grid-vertical-line" />
            <div data-hover="false" data-delay="0" className="w-dropdown">
              <div
                className="filter-select-field w-dropdown-toggle"
                id="w-dropdown-toggle-4"
                aria-controls="w-dropdown-list-4"
                aria-haspopup="menu"
                aria-expanded="false"
                role="button"
                tabIndex="0"
              >
                <div>Sizes (In)</div>
                <div className="icon-4 w-icon-dropdown-toggle" aria-hidden="true" />
              </div>
              <nav
                className="dropdown-list service column w-dropdown-list"
                id="w-dropdown-list-4"
                aria-labelledby="w-dropdown-toggle-4"
              >
                <div className="w-layout-grid grid-23">
                  <div className="form-block-filter w-form">
                    <form
                      id="email-form-3"
                      name="email-form-3"
                      data-name="Email Form 3"
                      className="form-3"
                    >
                      <div className="div-block-97">
                        <div>Inches</div>
                      </div>
                      <div className="div-block-95">
                        <label className="w-checkbox checkbox-field-2">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-3" />
                          <input
                            type="checkbox"
                            id="checkbox-7"
                            name="checkbox-7"
                            data-name="Checkbox 7"
                            style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                          />
                          <span className="w-form-label">14x14</span>
                        </label>
                      </div>
                      <div className="div-block-95">
                        <label className="w-checkbox checkbox-field-2">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-3" />
                          <input
                            type="checkbox"
                            id="checkbox-7"
                            name="checkbox-7"
                            data-name="Checkbox 7"
                            style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                          />
                          <span className="w-form-label">12x18</span>
                        </label>
                      </div>
                      <div className="div-block-95">
                        <label className="w-checkbox checkbox-field-2">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-3" />
                          <input
                            type="checkbox"
                            id="checkbox-7"
                            name="checkbox-7"
                            data-name="Checkbox 7"
                            style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                          />
                          <span className="w-form-label">16x16</span>
                        </label>
                      </div>
                      <div className="div-block-95">
                        <label className="w-checkbox checkbox-field-2">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-3" />
                          <input
                            type="checkbox"
                            id="checkbox-7"
                            name="checkbox-7"
                            data-name="Checkbox 7"
                            style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                          />
                          <span className="w-form-label">16x20</span>
                        </label>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                  <div className="form-block-filter w-form">
                    <form
                      id="email-form-3"
                      name="email-form-3"
                      data-name="Email Form 3"
                      className="form-3"
                    >
                      <div className="div-block-97">
                        <div>Centimeters</div>
                      </div>
                      <div className="div-block-95">
                        <label className="w-checkbox checkbox-field-2">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-3" />
                          <input
                            type="checkbox"
                            id="checkbox-7"
                            name="checkbox-7"
                            data-name="Checkbox 7"
                            style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                          />
                          <span className="w-form-label">21x30 cm</span>
                        </label>
                      </div>
                      <div className="div-block-95">
                        <label className="w-checkbox checkbox-field-2">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-3" />
                          <input
                            type="checkbox"
                            id="checkbox-7"
                            name="checkbox-7"
                            data-name="Checkbox 7"
                            style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                          />
                          <span className="w-form-label">30x40 cm</span>
                        </label>
                      </div>
                      <div className="div-block-95">
                        <label className="w-checkbox checkbox-field-2">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-3" />
                          <input
                            type="checkbox"
                            id="checkbox-7"
                            name="checkbox-7"
                            data-name="Checkbox 7"
                            style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                          />
                          <span className="w-form-label">50x70 cm</span>
                        </label>
                      </div>
                      <div className="div-block-95">
                        <label className="w-checkbox checkbox-field-2">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-3" />
                          <input
                            type="checkbox"
                            id="checkbox-7"
                            name="checkbox-7"
                            data-name="Checkbox 7"
                            style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                          />
                          <span className="w-form-label">61x91 cm</span>
                        </label>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div className="container-info-filter bg">
                  <div className="icon-3 small right-margin x2 w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none" />
                      <polyline
                        points="79.833 99.716 31.833 99.716 31.833 51.716"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                      <path
                        d="M65.7746,190.2254a88,88,0,1,0,0-124.4508L31.83348,99.71573"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>Reset All</div>
                </div>
              </nav>
            </div>
          </div>
          <div data-hover="false" data-delay="0" className="dropdown-4 w-dropdown">
            <div
              className="filter-select-field radius w-dropdown-toggle"
              id="w-dropdown-toggle-5"
              aria-controls="w-dropdown-list-5"
              aria-haspopup="menu"
              aria-expanded="false"
              role="button"
              tabIndex="0"
            >
              <div className="icon-3 small left-margin w-embed">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                  <rect width="256" height="256" fill="none" />
                  <polyline
                    points="144 167.994 184 207.994 223.999 168"
                    fill="none"
                    stroke="currentcolor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                  <line
                    x1="184"
                    y1="111.99347"
                    x2="184"
                    y2="207.99347"
                    fill="none"
                    stroke="#currentcolor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                  <line
                    x1="48"
                    y1="127.99353"
                    x2="119.99902"
                    y2="127.99353"
                    fill="none"
                    stroke="currentcolor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                  <line
                    x1="48"
                    y1="63.99353"
                    x2="183.99902"
                    y2="63.99353"
                    fill="none"
                    stroke="currentcolor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                  <line
                    x1="48"
                    y1="191.99353"
                    x2="104"
                    y2="191.99353"
                    fill="none"
                    stroke="currentcolor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                </svg>
              </div>
            </div>
            <nav
              className="dropdown-list sort w-dropdown-list"
              id="w-dropdown-list-5"
              aria-labelledby="w-dropdown-toggle-5"
            >
              <div className="form-block-filter w-form">
                <form
                  id="email-form-3"
                  name="email-form-3"
                  data-name="Email Form 3"
                  className="form-3"
                >
                  <label className="radio-button-field w-radio">
                    <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button w-radio-input" />
                    <input
                      type="radio"
                      data-name="Radio 6"
                      id="radio-6"
                      name="radio"
                      value="Radio"
                      style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                    />
                    <span className="w-form-label">Ascending (A-Z)</span>
                  </label>
                  <label className="radio-button-field w-radio">
                    <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button w-radio-input" />
                    <input
                      type="radio"
                      data-name="Radio 7"
                      id="radio-7"
                      name="radio"
                      value="Radio"
                      style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                    />
                    <span className="w-form-label">Descending (Z-A)</span>
                  </label>
                  <label className="radio-button-field w-radio">
                    <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button w-radio-input" />
                    <input
                      type="radio"
                      data-name="Radio 8"
                      id="radio-8"
                      name="radio"
                      value="Radio"
                      style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                    />
                    <span className="w-form-label">Newest to Oldest</span>
                  </label>
                  <label className="radio-button-field w-radio">
                    <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button w-radio-input" />
                    <input
                      type="radio"
                      data-name="Radio 9"
                      id="radio-9"
                      name="radio"
                      value="Radio"
                      style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                    />
                    <span className="w-form-label">Oldest to Newest</span>
                  </label>
                </form>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>Oops! Something went wrong while submitting the form.</div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div className="card-frame">
      {[...Array(10).keys()].map(() => (
        <div className="card">
          <img
            src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512dd3ddbda9d7_Captura%20de%20Pantalla%202020-12-21%20a%20la(s)%209.46.30%20a.%C2%A0m..png"
            loading="lazy"
            sizes="(max-width: 479px) 42vw, (max-width: 991px) 45vw, (max-width: 1279px) 30vw, (max-width: 1439px) 22vw, (max-width: 1919px) 23vw, 16vw"
            srcSet="
        https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512dd3ddbda9d7_Captura%2520de%2520Pantalla%25202020-12-21%2520a%2520la(s)%25209.46.30%2520a.%25C2%25A0m.-p-500.png 500w,
        https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512dd3ddbda9d7_Captura%20de%20Pantalla%202020-12-21%20a%20la(s)%209.46.30%20a.%C2%A0m..png                         504w
      "
            alt=""
            className="image-card"
          />
          <div className="product-content-div">
            <div className="product-name">Personalized Enhanced Matte Paper Poster</div>
            <div className="dropdown-wrap dropdown-wrap__inline">
              <div
                data-delay="90"
                data-hover="false"
                data-w-id="2b9a0a74-be0d-a730-1d78-24814fa81395"
                className="dropdown-2 w-dropdown"
              >
                <div
                  className="dropdown-toggle-3 dropdown-toggle__inline align-left w-dropdown-toggle"
                  id="w-dropdown-toggle-6"
                  aria-controls="w-dropdown-list-6"
                  aria-haspopup="menu"
                  aria-expanded="false"
                  role="button"
                  tabIndex="0"
                >
                  <div className="rating-div">
                    <img
                      src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512d959ebda9ba_mark-as-favorite-star%20(1).svg"
                      loading="lazy"
                      id="w-node-_2b9a0a74-be0d-a730-1d78-24814fa81398-b2bda996"
                      alt=""
                      className="rating-image"
                    />
                    <img
                      src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512d959ebda9ba_mark-as-favorite-star%20(1).svg"
                      loading="lazy"
                      id="w-node-_2b9a0a74-be0d-a730-1d78-24814fa81399-b2bda996"
                      alt=""
                      className="rating-image"
                    />
                    <img
                      src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512d959ebda9ba_mark-as-favorite-star%20(1).svg"
                      loading="lazy"
                      id="w-node-_2b9a0a74-be0d-a730-1d78-24814fa8139a-b2bda996"
                      alt=""
                      className="rating-image"
                    />
                    <img
                      src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512d94f2bda9bb_half-filled-rating-star%20(1).svg"
                      loading="lazy"
                      id="w-node-_2b9a0a74-be0d-a730-1d78-24814fa8139b-b2bda996"
                      alt=""
                      className="rating-image"
                    />
                    <img
                      src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512d02e6bda9b6_bookmark-button%20(1).svg"
                      loading="lazy"
                      id="w-node-_2b9a0a74-be0d-a730-1d78-24814fa8139c-b2bda996"
                      alt=""
                      className="rating-image"
                    />
                  </div>
                  <div className="div-block-39">
                    <div
                      className="dropdown-toggle__icon dropdown-toggle__icon-inline"
                      style={{
                        transform:
                          'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                        transformStyle: 'preserve-3d',
                      }}
                    />
                    <div>200 Reviews</div>
                  </div>
                </div>
                <nav
                  style={{ height: 0, display: 'none' }}
                  className="dropdown-list-3 w-dropdown-list"
                  id="w-dropdown-list-6"
                  aria-labelledby="w-dropdown-toggle-6"
                >
                  <div className="dropdown-item-2" />
                  <div className="dropdown-item-2" />
                  <div className="dropdown-item-2" />
                  <div className="div-block-42">
                    <div className="w-layout-grid grid-6">
                      <div>5 Stars</div>
                      <div
                        id="w-node-_2b9a0a74-be0d-a730-1d78-24814fa813a9-b2bda996"
                        className="div-block-43"
                      >
                        <div className="div-block-44 _83" />
                      </div>
                      <div id="w-node-_2b9a0a74-be0d-a730-1d78-24814fa813ab-b2bda996">83%</div>
                    </div>
                    <div className="w-layout-grid grid-6">
                      <div>4 Stars</div>
                      <div
                        id="w-node-_2b9a0a74-be0d-a730-1d78-24814fa813b0-b2bda996"
                        className="div-block-43"
                      >
                        <div className="div-block-44 _11" />
                      </div>
                      <div id="w-node-_2b9a0a74-be0d-a730-1d78-24814fa813b2-b2bda996">11%</div>
                    </div>
                    <div className="w-layout-grid grid-6">
                      <div>3 Stars</div>
                      <div
                        id="w-node-_2b9a0a74-be0d-a730-1d78-24814fa813b7-b2bda996"
                        className="div-block-43"
                      >
                        <div className="div-block-44 _4" />
                      </div>
                      <div id="w-node-_2b9a0a74-be0d-a730-1d78-24814fa813b9-b2bda996">4%</div>
                    </div>
                    <div className="w-layout-grid grid-6">
                      <div className="text-block-11">2 Stars</div>
                      <div
                        id="w-node-_2b9a0a74-be0d-a730-1d78-24814fa813be-b2bda996"
                        className="div-block-43"
                      >
                        <div className="div-block-44 _2" />
                      </div>
                      <div id="w-node-_2b9a0a74-be0d-a730-1d78-24814fa813c0-b2bda996">2%</div>
                    </div>
                    <div className="w-layout-grid grid-6">
                      <div>1 Stars</div>
                      <div
                        id="w-node-_2b9a0a74-be0d-a730-1d78-24814fa813c5-b2bda996"
                        className="div-block-43"
                      >
                        <div className="div-block-44 _1" />
                      </div>
                      <div id="w-node-_2b9a0a74-be0d-a730-1d78-24814fa813c7-b2bda996">1%</div>
                    </div>
                  </div>
                  <a href="/" className="third-button small w-inline-block" tabIndex="0">
                    <div>See All Reviews</div>
                  </a>
                </nav>
              </div>
            </div>
            <div className="div-block-37">
              <div className="text">Starting from</div>
              <div className="div-block-45">
                <div className="text-block-9">$ 5.95</div>
                <div className="price-details-div">excl. VAT</div>
              </div>
            </div>
            <div className="product-details-div">
              <div className="text-block-9-copy">8x10cm</div>
              <div className="text-block-9-copy line">-</div>
              <div className="text-block-9-copy">24x36cm</div>
            </div>
            <div className="delivery-details-div">
              <img
                src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512ddc91bda9b9_united-states.svg"
                loading="lazy"
                alt=""
                className="image-77"
              />
              <div id="w-node-_2b9a0a74-be0d-a730-1d78-24814fa813dd-b2bda996" className="text">
                Estimated delivery Dec 16-18
              </div>
            </div>
          </div>
          <div className="div-block-46">
            <div>Bestseller</div>
          </div>
          <a
            data-w-id="2b9a0a74-be0d-a730-1d78-24814fa813e2"
            href="/"
            className="wishlist-button w-inline-block"
          >
            <div
              data-w-id="2b9a0a74-be0d-a730-1d78-24814fa813e3"
              data-animation-type="lottie"
              data-src="https://assets.website-files.com/5da82f08805cc148dc5a3e5f/5da82f08805cc13fe15a3e6b_1087-heart.json"
              data-loop="0"
              data-direction="1"
              data-autoplay="0"
              data-is-ix2-target="1"
              data-renderer="svg"
              data-default-duration="3.1"
              data-duration="0"
              className="lottie-heart"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 450 450"
                width="450"
                height="450"
                preserveAspectRatio="xMidYMid meet"
                style={{
                  width: '100%',
                  height: '100%',
                  transform: 'translate3d(0px, 0px, 0px)',
                }}
              >
                <defs>
                  <clipPath id="__lottie_element_10">
                    <rect width="450" height="450" x="0" y="0"></rect>
                  </clipPath>
                  <clipPath id="__lottie_element_12">
                    <path d="M0,0 L800,0 L800,600 L0,600z" />
                  </clipPath>
                </defs>
                <g clipPath="url(#__lottie_element_10)">
                  <g
                    clipPath="url(#__lottie_element_12)"
                    transform="matrix(1,0,0,1,-175,-75)"
                    opacity="1"
                    style={{ display: 'block' }}
                  >
                    <g style={{ display: 'none' }}>
                      <g>
                        <path />
                      </g>
                    </g>
                    <g style={{ display: 'none' }}>
                      <g>
                        <path />
                      </g>
                    </g>
                    <g style={{ display: 'none' }}>
                      <g>
                        <path />
                      </g>
                    </g>
                    <g style={{ display: 'none' }}>
                      <g>
                        <path />
                      </g>
                    </g>
                    <g style={{ display: 'none' }}>
                      <g>
                        <path />
                      </g>
                    </g>
                    <g style={{ display: 'none' }}>
                      <g>
                        <path />
                      </g>
                    </g>
                    <g style={{ display: 'none' }}>
                      <g>
                        <path />
                      </g>
                    </g>
                    <g transform="matrix(1,0,0,1,400,300)" opacity="1" style={{ display: 'block' }}>
                      <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                        <path
                          strokeLinecap="butt"
                          strokeLinejoin="miter"
                          fillOpacity="0"
                          strokeMiterlimit="4"
                          stroke="rgb(189,189,189)"
                          strokeOpacity="1"
                          strokeWidth="12"
                          d=" M20.62299919128418,-45.452999114990234 C12.675999641418457,-45.452999114990234 5.438000202178955,-42.41999816894531 0,-37.45000076293945 C-5.438000202178955,-42.41999816894531 -12.675999641418457,-45.452999114990234 -20.62299919128418,-45.452999114990234 C-37.51100158691406,-45.452999114990234 -51.20100021362305,-31.76300048828125 -51.20100021362305,-14.875 C-51.20100021362305,-5.144999980926514 -47.81999969482422,2.9149999618530273 -41.41999816894531,10.5600004196167 C-35.02000045776367,18.204999923706055 -0.019999999552965164,45.452999114990234 -0.019999999552965164,45.452999114990234 C-0.019999999552965164,45.452999114990234 35.196998596191406,18.32699966430664 41.45500183105469,10.378999710083008 C47.47200012207031,2.736999988555908 51.20100021362305,-5.085999965667725 51.20100021362305,-14.875 C51.20100021362305,-31.76300048828125 37.51100158691406,-45.452999114990234 20.62299919128418,-45.452999114990234z"
                        />
                      </g>
                    </g>
                    <g transform="matrix(0,0,0,0,400,300)" opacity="1" style={{ display: 'block' }}>
                      <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                        <path
                          fill="rgb(229,115,115)"
                          fillOpacity="1"
                          d=" M-0.03999999910593033,52.24800109863281 C-0.03999999910593033,52.24800109863281 -3.7060000896453857,49.393001556396484 -3.7060000896453857,49.393001556396484 C-7.341000080108643,46.56399917602539 -39.400001525878906,21.523000717163086 -46.02000045776367,13.616999626159668 C-53.54399871826172,4.63100004196167 -57.20199966430664,-4.947999954223633 -57.20199966430664,-15.668000221252441 C-57.20199966430664,-35.8380012512207 -40.792999267578125,-52.24800109863281 -20.62299919128418,-52.24800109863281 C-13.204999923706055,-52.24800109863281 -6.045000076293945,-50.013999938964844 0,-45.88100051879883 C6.044000148773193,-50.01499938964844 13.204999923706055,-52.24800109863281 20.62299919128418,-52.24800109863281 C40.792999267578125,-52.24800109863281 57.20199966430664,-35.8380012512207 57.20199966430664,-15.668000221252441 C57.20199966430664,-5.466000080108643 53.69499969482422,3.7360000610351562 46.16899871826172,13.295999526977539 C39.638999938964844,21.590999603271484 7.306000232696533,46.59000015258789 3.6410000324249268,49.41299819946289 C3.6410000324249268,49.41299819946289 -0.03999999910593033,52.24800109863281 -0.03999999910593033,52.24800109863281z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </a>
        </div>
      ))}
    </div>
  </div>
)

export default Product
