import { useCallback, useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import values from 'lodash/values'
import cn from 'classnames'
import { Link } from 'react-router-dom'

import { ReactComponent as LockIcon } from '../../images/icons/lock.svg'
import { ReactComponent as ErrorIcon } from '../../images/icons/error.svg'
import { ReactComponent as CheckIcon } from '../../images/icons/check-rounded.svg'

import { PROFILE_RULES } from '../../lib/const'
import { apiErrorMessage, isEmail } from '../../lib/utils'
import httpClient from '../../httpClient'

import ErrorMessage from '../common/ErrorMessage'
import AccountHeading from './AccountHeading'
import TextInput from './TextInput'

const BasicInformation = ({ user, onUpdate }) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm()
  const [apiError, setApiError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [validEmail, setValidEmail] = useState(null)
  const email = watch('email')

  useEffect(() => {
    if (!isEmail(email)) return
    ;(async () => {
      try {
        await httpClient.post('check_for_email', { email })
        setValidEmail(false)
      } catch (error) {
        setValidEmail(true)
      }
    })()
  }, [email])

  const handleUpdate = useCallback(async (data) => {
    try {
      setLoading(true)
      const profile = await httpClient.patch(`users/${user?.guid}`, data)
      onUpdate(profile)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setApiError(apiErrorMessage(error))
    }
  }, [])

  return (
    <form
      onSubmit={handleSubmit(handleUpdate)}
      id="address"
      className="address-info-wrap"
      style={{ display: 'block' }}
    >
      <div className="accordion-item-block no-margin">
        <AccountHeading title="Change Login Email" icon={LockIcon} />
        <Controller
          rules={PROFILE_RULES.email}
          name="email"
          defaultValue={user?.email ?? ''}
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              label="EMAIL ACCOUNT"
              placeholder="myemail@email.com"
              name="email"
            >
              {isEmail(email) && (
                <div className="eye-2" style={{ opacity: 1, height: '40px' }}>
                  <div className="icon w-embed">
                    {validEmail ? <CheckIcon color="#73d13d" /> : <ErrorIcon color="#ff4d4f" />}
                  </div>
                </div>
              )}
            </TextInput>
          )}
        />
        <div className="w-layout-grid grid-form _1fr">
          <div className="button-form-container" style={{ width: '100%' }}>
            <button
              type="submit"
              disabled={isSubmitting || loading || !validEmail}
              className={cn('button-2 secondary w-inline-block', {
                disabled: !validEmail || loading || isSubmitting,
              })}
            >
              <div>{loading || isSubmitting ? 'Please wait...' : 'Save Email'}</div>
            </button>
          </div>

          <Link
            to="/forgot-password"
            id="w-node-e07faba9-89b0-1fef-2c4c-2e54de350a45-0ce80a13"
            className="button-2 secondary w-inline-block"
          >
            <div>Change Password</div>
          </Link>
        </div>
      </div>

      {(values(errors)[0] || apiError) && (
        <div className="form-item-2">
          <ErrorMessage errors={values(errors)?.[0]?.message || apiError} />
        </div>
      )}
    </form>
  )
}

export default BasicInformation
