import { useState, useCallback, useEffect, useMemo } from 'react'
import queryString from 'query-string'
import { useNavigate, useLocation } from 'react-router'

import { useTabs, usePhotographers } from '../hooks'
import { QUERYSTRING_PARSE_FORMAT } from '../lib/const'

import Loading from '../components/common/Loading'
import ErrorMessage from '../components/common/ErrorMessage'
import PhotographersDatabaseSearchBar from '../components/PhotographersDatabase/PhotographersDatabaseSearchBar'
import PhotographersDatabaseTab from '../components/PhotographersDatabase/PhotographersDatabaseTab'
import PhotographersDatabaseTableHeader from '../components/PhotographersDatabase/PhotographersDatabaseTableHeader'
import PhotographersDatabaseTable from '../components/PhotographersDatabase/PhotographersDatabaseTable'

const PhotographersDatabasePage = () => {
  const [term, setTerm] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const queryParams = queryString.parse(location.search, QUERYSTRING_PARSE_FORMAT)

    setTerm(queryParams.term)
  }, [])

  useEffect(() => {
    const search = queryString.stringify({ term }, QUERYSTRING_PARSE_FORMAT)

    navigate({ pathname: location.pathname, search }, { replace: true })
  }, [term])

  const { photographers, error, loading } = usePhotographers()

  const usersNotApproved = useMemo(
    () => photographers?.data.filter((ph) => !ph.attributes.userApproved),
    [photographers],
  )
  const usersApproved = useMemo(
    () => photographers?.data.filter((ph) => ph.attributes.userApproved),
    [photographers],
  )
  const content = [
    <PhotographersDatabaseTable key="usersApproved" users={usersApproved} searchTerm={term} />,
    <PhotographersDatabaseTable
      key="usersNotApproved"
      users={usersNotApproved}
      searchTerm={term}
    />,
  ]

  const { currentItem, changeItem, currentItemIndex } = useTabs(0, content)

  const handleOnSearchChange = useCallback((_term) => setTerm(_term), [])

  if (loading) {
    return <Loading message="Loading photographers" />
  }

  if (error) {
    return <ErrorMessage errors={error} />
  }

  return (
    <div className="admin-photographers-container">
      <div className="admin-photographer-subcontainer big-screen">
        <div data-duration-in="300" data-duration-out="100" className="tabs w-tabs">
          <div className="tabs-menu-2 w-tab-menu" role="tablist">
            <PhotographersDatabaseTab
              onClick={() => changeItem(0)}
              isActive={currentItemIndex === 0}
            >
              <div>Photographers Database</div>
            </PhotographersDatabaseTab>
            <PhotographersDatabaseTab
              onClick={() => changeItem(1)}
              isActive={currentItemIndex === 1}
            >
              <div>Pending to be approved</div>
              <div className="div-block-130">
                <div>{usersNotApproved?.length}</div>
              </div>
            </PhotographersDatabaseTab>
          </div>
          <div className="tabs-content w-tab-content">
            <div
              className="w-tab-pane w--tab-active"
              id={`w-tabs-0-data-w-pane-${currentItemIndex}`}
              role="tabpanel"
            >
              <div className="block-admin-photographer-table">
                <div className="sub-block-admin-photographer">
                  <PhotographersDatabaseTableHeader />
                  {currentItem}
                </div>
              </div>
            </div>
          </div>
        </div>
        <PhotographersDatabaseSearchBar onChange={handleOnSearchChange} term={term} />
      </div>
    </div>
  )
}

export default PhotographersDatabasePage
