import { Helmet } from 'react-helmet'
import { Navigate, useLocation } from 'react-router-dom'
import { getAuthToken } from '../lib/utils'

import Layout from './Layout'

const PrivateRoute = ({ children, withLayout, title }) => {
  const location = useLocation()

  if (!getAuthToken()) return <Navigate to="/login" state={{ from: location }} replace />

  return (
    <>
      <Helmet>
        <title>{`MuseaLab - ${title}`}</title>
      </Helmet>
      {withLayout ? <Layout>{children}</Layout> : children}
    </>
  )
}

export default PrivateRoute
