import { Link } from 'react-router-dom'

import { dollarTemplate } from '../../lib/utils'
import { ReactComponent as NextIcon } from '../../images/icons/next.svg'

const OrderCheckout = ({ order }) => (
  <div
    id="w-node-_0ce5f8fc-849e-0435-1e68-45eae83274ca-69e80a54"
    className="div-block-white max-width-500px margin-top-60"
  >
    <div className="w-layout-grid grid-container-div-block">
      <div className="div-big-text">
        <h2 className="heading-title right-margin">Order Checkout</h2>
      </div>
      <div className="line-horizontal" />
      <div className="w-layout-grid summary-grid-elements">
        <div className="w-layout-grid main-summary-order-elements">
          <div className="text-container">
            <div>Subtotal</div>
          </div>
          <div className="text-container">
            <h4 className="sub-text-heading">{dollarTemplate(order.attributes.subtotal)}</h4>
          </div>
        </div>
        <div className="w-layout-grid main-summary-order-elements">
          <div className="text-container">
            <div>Discount</div>
          </div>
          <div className="text-container">
            <h4 className="sub-text-heading">{dollarTemplate(order.attributes.discount)}</h4>
          </div>
        </div>
        <div className="w-layout-grid main-summary-order-elements">
          <div className="text-container">
            <div>Credit</div>
          </div>
          <div className="text-container">
            <h4 className="sub-text-heading">{dollarTemplate(order.attributes.creditApplied)}</h4>
          </div>
        </div>
        <div className="w-layout-grid main-summary-order-elements">
          <div className="text-container">
            <div>Boutique</div>
          </div>
          <div className="text-container">
            <h4 className="sub-text-heading">
              {dollarTemplate(order.attributes.boutiqueShippingPrice)}
            </h4>
          </div>
        </div>
        <div className="w-layout-grid main-summary-order-elements">
          <div className="text-container">
            <div>Shipping</div>
          </div>
          <div className="text-container">
            <h4 className="sub-text-heading">{dollarTemplate(order.attributes.shippingTotal)}</h4>
          </div>
        </div>
      </div>
      <div className="line-horizontal" />
      <div className="w-layout-grid main-summary-order-elements">
        <div className="text-container">
          <h4 className="main-sub-text-heading">Total</h4>
        </div>
        <div className="text-container">
          <h4 className="main-sub-text-heading">{dollarTemplate(order.attributes.total)}</h4>
        </div>
      </div>
      <div className="w-layout-grid grid-58 grid-buttons">
        <Link
          to={`/product-selection/${order.attributes.guid}`}
          id="w-node-_0ce5f8fc-849e-0435-1e68-45eae83274fd-69e80a54"
          className="button secondary w-inline-block"
        >
          <div>Keep Shopping</div>
        </Link>
        <Link
          to={`/checkout?order=${order.attributes.guid}`}
          id="w-node-_0ce5f8fc-849e-0435-1e68-45eae8327500-69e80a54"
          className="button w-inline-block"
        >
          <div className="icon right-margin w-embed">
            <NextIcon />
          </div>
          <div>Go to Checkout</div>
        </Link>
      </div>
    </div>
  </div>
)

export default OrderCheckout
