import classNames from 'classnames'

import { useState } from 'react'
import { useLottie } from '../../../hooks'

import animationData from '../../../animations/orderName.json'

import { ReactComponent as InfoIcon } from '../../../images/icons/info.svg'
import { ReactComponent as SaveIcon } from '../../../images/icons/save.svg'

const lottieOptions = {
  animationData,
  loop: true,
  autoplay: true,
  renderer: 'svg',
  direction: 1,
  duration: 0,
}

const OrderInit = ({ onCreate, order, loading }) => {
  const [, container] = useLottie(lottieOptions)
  const [orderName, setOrderName] = useState('')

  const handleNameChange = (e) => {
    setOrderName(e.target.value)
  }

  const handleOnSubmit = () => {
    onCreate(orderName)
  }

  return (
    <>
      <h2 className="heading-title-2">New Order</h2>
      <div className="line-horizontal" />
      <div className="w-layout-grid grid-form">
        <div
          id="w-node-_32811dee-b088-9225-f64f-fc05d52466f5-ece80a36"
          className="input-container paper-type"
        >
          <div className="label-container">
            <div className="div-block-150">
              <div className="sub-label-form">Order name</div>
              <div className="icon mini margin-left opacity70 w-embed">
                <InfoIcon />
              </div>
            </div>
          </div>
          <div className="form-block-input w-form">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              className="form-input select-field"
            >
              <div className="select-field-wrapper">
                <input
                  type="text"
                  className={classNames('text-field-2 font-size-14 w-input', {
                    disabled: loading || order,
                  })}
                  maxLength="256"
                  name="field-4"
                  placeholder="Your Order Name"
                  onChange={handleNameChange}
                />
              </div>
            </form>
          </div>
          <div className="divider-16px" />
          {!order && (
            <button
              onClick={handleOnSubmit}
              type="button"
              disabled={order || loading}
              className={classNames('button-2 secondary w-inline-block', {
                disabled: loading || order,
              })}
            >
              <div className="icon right-margin w-embed">
                <SaveIcon />
              </div>
              <div>Save Order Name</div>
            </button>
          )}
        </div>
      </div>
      {!order && (
        <div
          ref={container}
          className="lottie-animation-5"
          style={{ filter: 'invert(0)' }}
        />
      )}
    </>
  )
}

export default OrderInit
