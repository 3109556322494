import { useCallback } from 'react'
import { ReactComponent as ChevronDownIcon } from '../../../../images/icons/chevron-down.svg'
import { ReactComponent as InfoIcon } from '../../../../images/icons/info.svg'
import { useToggle } from '../../../../hooks'
import { formatAddress } from '../../../../lib/utils'
import { LAB_ORDER_ATTRIBUTES } from '../../../../lib/const'
import AddAddress from './AddAddress'
import { useCheckout } from '../../useCheckout'

const LOCAL_PICKUP = 'localPickup'
const NEW_ADDRESS = 'newAddress'

const Address = () => {
  const { updateOrder, profile, order } = useCheckout()
  const [showNewAddress, toggleShowNewAddress, setShowNewAddress] = useToggle(false)

  const addresses = profile?.addresses?.data

  const orderWithAddress = order?.data?.attributes?.shippingAddress?.guid
  const orderWithLocalPickup = order?.data?.attributes?.localPickup ? LOCAL_PICKUP : null

  const handleChange = useCallback(async (e) => {
    const newAddressId = e.target.value
    if (newAddressId === NEW_ADDRESS) {
      toggleShowNewAddress()
      return
    }
    setShowNewAddress(false)
    if (newAddressId === LOCAL_PICKUP) {
      updateOrder({
        type: LAB_ORDER_ATTRIBUTES.localPickup,
        value: true,
      })
    } else {
      updateOrder({
        type: LAB_ORDER_ATTRIBUTES.localPickup,
        value: false,
      })
      updateOrder({
        type: LAB_ORDER_ATTRIBUTES.shippingAddressId,
        value: newAddressId,
      })
    }
  }, [])

  return (
    <>
      <div className="w-layout-grid grid-form _1fr">
        <div
          id="w-node-_83a8a9d7-764d-a757-9321-a96ba32c3ac9-80e80a52"
          className="input-container paper-type"
        >
          <div className="label-container">
            <div className="label-subcontainer">
              <div className="sub-label-form">Ship to</div>
              <div className="icon mini margin-left opacity70 w-embed">
                <InfoIcon />
              </div>
            </div>
          </div>
          <div className="form-block-input w-form">
            <div
              id="email-form"
              name="email-form"
              data-name="Email Form"
              className="form-input select-field"
            >
              <div className="select-field-wrapper">
                <select
                  id="address"
                  name="address"
                  className="form-select-field font-size-14 w-select"
                  onChange={handleChange}
                  value={orderWithAddress || orderWithLocalPickup || ''}
                >
                  {!orderWithAddress && !orderWithLocalPickup && (
                    <option value="" disabled>
                      Select an address
                    </option>
                  )}
                  {addresses?.map(({ attributes }) => (
                    <option key={attributes.guid} value={attributes.guid}>
                      {formatAddress(attributes)}
                    </option>
                  ))}
                  <option value={NEW_ADDRESS}>New Address</option>
                  <option value={LOCAL_PICKUP}>Local pickup in store</option>
                </select>
                <div className="div-block-63">
                  <ChevronDownIcon className="img-icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showNewAddress && <AddAddress onSave={toggleShowNewAddress} />}
    </>
  )
}

export default Address
