import { useState, useEffect } from 'react'
import axios from 'axios'
import sanitize from 'sanitize-filename'
import { useParams } from 'react-router-dom'
import httpClient from '../httpClient'

import ImagesUpload from '../components/PrintOrder/PrintOrderCreate/ImagesUpload'
import NoImages from '../components/PrintOrder/PrintOrderCreate/NoImages'
import Loading from '../components/common/Loading'
import ImagePreview from '../components/PrintOrder/PrintOrderCreate/ImagePreview'
import SelectProducts from '../components/PrintOrder/PrintOrderCreate/SelectProducts'
import { apiErrorMessage } from '../lib/utils'

const PrintOrderCreate = () => {
  const { id: labOrderGuid } = useParams()
  const [uploadsPreview, setUploadPreview] = useState([])
  const [loading, setLoading] = useState(false)

  const [, setApiError] = useState(false)

  const handleUploadPhoto = async (file) => {
    const photoFileName = sanitize(file.name)

    try {
      setLoading('Uploading')
      setApiError(null)
      const { signedUrl } = await httpClient.post(
        `lab_orders/${labOrderGuid}/create_presigned_url`,
        {
          labOrderGuid,
          photoFileName,
        },
      )

      await axios.put(signedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      })

      const { status, id } = await httpClient.patch(
        `lab_orders/${labOrderGuid}/photograph_uploaded`,
        {
          labOrderGuid,
          photoFileName,
        },
      )
      if (status === 'ok') {
        setUploadPreview((prev) => [
          {
            ...file,
            id,
            preview: URL.createObjectURL(file),
          },
          ...prev,
        ])
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setApiError(apiErrorMessage(err))
    }
  }

  // TODO: Error notification
  // TODO: Success notification

  useEffect(
    () => () => {
      uploadsPreview.forEach((file) => URL.revokeObjectURL(file.preview))
    },
    [],
  )

  return (
    <div className="sub-grid-container-print-order">
      <div className="print-order-sidebar-container">
        <div className="print-order-sidebar grid absolute">
          <div className="accordion-item-block">
            <ImagesUpload onUpload={handleUploadPhoto} />
            {!!uploadsPreview.length && <SelectProducts id={labOrderGuid} />}
          </div>
        </div>
      </div>
      {loading && <Loading message={loading} />}
      {!loading && uploadsPreview.length ? (
        <div className="img-container-print-order">
          <div className="w-layout-grid grid-images-print-order">
            {uploadsPreview.map((preview, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ImagePreview key={`preview-${index}`} image={preview} />
            ))}
          </div>
        </div>
      ) : (
        <NoImages />
      )}
    </div>
  )
}

export default PrintOrderCreate
