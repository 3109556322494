import MuseaLogo from '../../images/Green_Musea_Logo.svg'

const Heading = ({ title, withBrandName }) => (
  <div className="div-block-5">
    <img src={MuseaLogo} loading="lazy" alt="MuseaLab" className="image-66" />
    <h1 className="heading-6">
      {title && `${title} `}
      {withBrandName && (
        <>
          <span className="text-span-3">MUSEA </span>
          Lab
        </>
      )}
    </h1>
  </div>
)

export default Heading
