import { useState, useCallback, useEffect } from 'react'
import httpClient from '../httpClient'

import { apiErrorMessage } from '../lib/utils'

import Loading from '../components/common/Loading'
import ErrorMessage from '../components/common/ErrorMessage'
import useProfile from '../hooks/useProfile'
import ReferralReport from '../components/Credit/ReferralReport'
import ClientCredit from '../components/Credit/ClientCredit'

const Credit = () => {
  const {
    profile,
    error: profileError,
    loading: loadingProfile,
    onProfileUpdate,
    refetchUser,
  } = useProfile()
  const user = profile?.user.data.attributes

  const [referrals, setReferrals] = useState(null)
  const [referralsError, setReferralError] = useState(null)
  const [loadingReferrals, setLoadingReferrals] = useState(false)

  const fetchReferrals = useCallback(async () => {
    try {
      setLoadingReferrals('Loading referrals')
      setReferralError(null)
      const res = await httpClient('accounts/referrals')
      setReferrals(res?.referrals)
      setLoadingReferrals(false)
    } catch (err) {
      setReferralError(apiErrorMessage(err))
      setLoadingReferrals(false)
    }
  }, [])

  useEffect(() => {
    fetchReferrals()
  }, [])

  if (loadingProfile || loadingReferrals) {
    return <Loading message="Loading profile and referrals" />
  }

  if (profileError || referralsError) {
    return <ErrorMessage errors={profileError || referralsError} />
  }

  return (
    <div className="client-credit-container">
      <div className="w-layout-grid grid-59-copy">
        <ClientCredit user={user} onUpdate={onProfileUpdate} onRefetchUser={refetchUser} />
        <ReferralReport referrals={referrals} reportLink={user?.referralReportLink} />
      </div>
    </div>
  )
}

export default Credit
