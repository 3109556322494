/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
import { forwardRef, useCallback, useEffect } from 'react'
import cn from 'classnames'
import capitalize from 'lodash/capitalize'
import values from 'lodash/values'
import { useForm, Controller } from 'react-hook-form'

import { usStates } from '../../../../lib/const'
import { ReactComponent as ChevronDownIcon } from '../../../../images/icons/chevron-down.svg'

import ErrorMessage from '../../../common/ErrorMessage'
import { useCheckout } from '../../useCheckout'

const MIN_INPUT_LENGHT = 1

const InputText = forwardRef((props, ref) => (
  <div
    id="w-node-f45764df-f887-a87d-6483-5aabf989154f-5683c88d"
    className="input-container paper-type"
  >
    <div className="label-container">
      <div className="label-subcontainer">
        <div className="sub-label-form">{props.label}</div>
      </div>
    </div>
    <div className="form-block-input w-form">
      <div className="form-input select-field">
        <div className="select-field-wrapper">
          <input
            ref={ref}
            {...props}
            type="text"
            className="text-field-2 font-size-14 w-input"
            minLength="1"
            placeholder={props.placeholder}
          />
        </div>
      </div>
    </div>
  </div>
))

const InputSelect = forwardRef((props, ref) => (
  <div
    id="w-node-_4e25e9d2-a930-bab8-236c-b9fd44ffcb45-5683c88d"
    className="input-container paper-type"
  >
    <div className="label-container">
      <div className="label-subcontainer">
        <div className="sub-label-form">{props.label}</div>
      </div>
    </div>
    <div className="form-block-input w-form">
      <div className="form-input select-field">
        <div className="select-field-wrapper states">
          <select ref={ref} {...props} className="select-field-wrapper select w-select">
            <option value="" disabled>
              Select a state
            </option>
            {usStates.map((state) => (
              <option key={state.abbreviation} value={state.abbreviation}>
                {capitalize(state.name)}
              </option>
            ))}
          </select>
          <div className="div-block-63">
            <ChevronDownIcon className="img-icon" />
          </div>
        </div>
      </div>
    </div>
  </div>
))

const AddAddress = ({ onSave }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm()
  const { addAddress } = useCheckout()

  const handleSaveAddress = useCallback(async (newAddress) => {
    await addAddress(newAddress)
    onSave()
  }, [])

  useEffect(() => {
    if (errors) {
      values(errors?.[0]?.ref?.focus())
    }
  }, [errors])

  return (
    <form
      onSubmit={handleSubmit(handleSaveAddress)}
      id="address"
      className="address-info-wrap"
      style={{ display: 'block' }}
    >
      <div className="w-layout-grid grid-form _1fr">
        <Controller
          rules={{
            required: {
              value: true,
              message: 'Street Address is required.',
            },
            minLength: {
              value: MIN_INPUT_LENGHT,
              message: `Min lengh is ${MIN_INPUT_LENGHT}.`,
            },
          }}
          name="streetAddress"
          defaultValue=""
          control={control}
          render={({ field }) => (
            <InputText {...field} label="Street address" placeholder="My Street Road 42" />
          )}
        />
        <Controller
          rules={{
            required: {
              value: true,
              message: 'Street Address 2 is required.',
            },

            minLength: {
              value: MIN_INPUT_LENGHT,
              message: `Min lengh is ${MIN_INPUT_LENGHT}.`,
            },
          }}
          name="streetAddressTwo"
          defaultValue=""
          control={control}
          render={({ field }) => (
            <InputText {...field} label="Street address 2" placeholder="My Street Road 42" />
          )}
        />
        <Controller
          rules={{
            required: {
              value: true,
              message: 'City is required.',
            },

            minLength: {
              value: MIN_INPUT_LENGHT,
              message: `Min lengh is ${MIN_INPUT_LENGHT}.`,
            },
          }}
          name="city"
          defaultValue=""
          control={control}
          render={({ field }) => <InputText {...field} label="City" placeholder="New York" />}
        />

        <Controller
          rules={{
            required: { value: true, message: 'State is required' },
          }}
          name="state"
          defaultValue=""
          control={control}
          render={({ field }) => <InputSelect {...field} label="State" />}
        />

        <Controller
          rules={{
            required: {
              value: true,
              message: 'Zip Code is required.',
            },
            maxLength: {
              value: 5,
              message: 'ZIP Codes are five digits long.',
            },
            minLength: {
              value: 5,
              message: 'ZIP Codes are five digits long.',
            },
          }}
          name="zip"
          defaultValue=""
          control={control}
          render={({ field }) => <InputText {...field} label="Zip code" placeholder="08011" />}
        />
      </div>
      <div>
        <button
          type="submit"
          data-w-id="646fe368-d170-d8bf-dfc5-b4e9352eb5d9"
          className={cn('button secondary margin-top w-inline-block', {
            disabled: isSubmitting,
          })}
        >
          <div>Save New Address</div>
        </button>
      </div>
      {values(errors) && <ErrorMessage errors={values(errors)?.[0]?.message} />}
    </form>
  )
}

export default AddAddress
