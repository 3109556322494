import debounce from 'lodash/debounce'
import { DEFAULT_DEBOUNCE } from '../../lib/const'

const PhotographersDatabaseSearchBar = ({ onChange, term }) => {
  const handleOnCHange = debounce(
    (e) => onChange(e.target.value === '' ? null : e.target.value),
    DEFAULT_DEBOUNCE,
  )

  return (
    <div className="search-bar-div">
      <div>
        <form action="/search" className="search tab w-form">
          <input type="submit" value="" className="search-button w-button" />
          <input
            type="search"
            className="input w-input"
            maxLength="256"
            name="query"
            placeholder="Search by Name, Email, etc..."
            defaultValue={term}
            id="search"
            required=""
            onChange={handleOnCHange}
          />
        </form>
      </div>
    </div>
  )
}

export default PhotographersDatabaseSearchBar
