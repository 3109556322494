import { ReactComponent as DownloadIcon } from '../../../images/icons/dowload-alternative.svg'
import ReferralReportItem from './ReferralReportItem'

const ReferralReport = ({ referrals, reportLink }) => (
  <div className="client-credit-table">
    <div className="w-layout-grid client-credit-grid">
      <div className="div-big-text">
        <h2 className="heading-title right-margin">Refferal Report</h2>
        <div className="white-button">
          <a href={reportLink} className="icon small w-embed" style={{ color: 'rgba(51,51,51)' }}>
            <DownloadIcon />
          </a>
        </div>
      </div>
      <div className="line-horizontal" />
      <div className="client-credit-block-table">
        <div className="client-credit-table-element header">
          <div className="client-credit-table-content">
            <div>Name</div>
          </div>
          <div className="client-credit-table-content">
            <div>Company Name</div>
          </div>
          <div className="client-credit-table-content">
            <div>Email</div>
          </div>
          <div className="client-credit-table-content credit-earned">
            <div>Credit Earned</div>
          </div>
        </div>
        <div className="w-layout-grid grid-60">
          {referrals?.map((referral) => (
            <ReferralReportItem key={referral.email} referral={referral} />
          ))}
        </div>
      </div>
    </div>
  </div>
)

export default ReferralReport
