import { useEffect, useRef } from 'react'

const createUseLottie = (lottie) => (options) => {
  const container = useRef(null)
  let instance = null

  useEffect(() => {
    instance = lottie.loadAnimation({ container: container.current, ...options })

    return () => instance.destroy()
  }, [])

  return [instance, container]
}

export default createUseLottie
