import { useState } from 'react'
import './SignUp.css'

import { SIGN_UP_STEPS } from '../lib/const'

import Step1 from '../components/SignUp/Step1'
import Step2 from '../components/SignUp/Step2'
import Step3 from '../components/SignUp/Step3'

const SignUpPage = () => {
  const [step, setStep] = useState(SIGN_UP_STEPS.registration)

  const handleNextStep = (nextStep) => setStep(nextStep)

  if (step === SIGN_UP_STEPS.registration) {
    return <Step1 onNextStep={handleNextStep} />
  }

  if (step === SIGN_UP_STEPS.referral) {
    return <Step2 onNextStep={handleNextStep} />
  }

  return <Step3 />
}
export default SignUpPage
