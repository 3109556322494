import { ReactComponent as ChevronDownIcon } from '../../../../images/icons/chevron-down.svg'
import { ReactComponent as OrderIcon } from '../../../../images/icons/order-alternative.svg'
import { ReactComponent as FilterIcon } from '../../../../images/icons/filter.svg'

const PhotographerOrdersSummaryHeader = () => (
  <div className="sub-heading-container both-edges">
    <div className="div-block-278">
      <div className="icon right-margin w-embed">
        <OrderIcon />
      </div>
      <h2 className="heading-title-2">Orders Summary</h2>
    </div>
    <div className="w-layout-grid grid-78">
      <div className="input-container">
        <div className="form-block-15 w-form">
          <form
            id="email-form"
            name="email-form"
            data-name="Email Form"
            className="form-input select-field _2nd-color"
          >
            <div className="select-field-wrapper secondary filter">
              <div className="icon small x2 top-margin w-embed">
                <FilterIcon />
              </div>
              <select
                id="save"
                name="field-3"
                data-name="Field 3"
                className="form-select-field secondary w-select"
              >
                <option value="Filter by all time">Filter by all time</option>
                <option value="2020">2020</option>
                <option value="2019">2019</option>
                <option value="2018">2018</option>
                <option value="2017">2017</option>
              </select>
              <div className="div-block-63">
                <ChevronDownIcon className="img-icon" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
)

export default PhotographerOrdersSummaryHeader
