import { useForm, Controller } from 'react-hook-form'
import cn from 'classnames'
import ErrorMessage from '../../common/ErrorMessage'
import { LAB_ORDER_ATTRIBUTES } from '../../../lib/const'
import { useCheckout } from '../useCheckout'

const DiscountCode = () => {
  const { order, applyDiscount } = useCheckout()
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm()

  const currentDiscountCode = order?.data?.attributes?.discountCode?.code
  const watchCode = watch('code')

  const handleValidateCode = async ({ code }) => {
    applyDiscount({
      type: LAB_ORDER_ATTRIBUTES.discountCode,
      value: code,
    })
  }

  return (
    <form
      id="email-form"
      name="email-form"
      data-name="Email Form"
      className="form-9"
      aria-hidden="true"
      onSubmit={handleSubmit(handleValidateCode)}
    >
      <div className="w-layout-grid grid-55">
        <div id="w-node-_38c5b4ef-b6b0-9d63-4a55-1fc4bcfb10da-5683c88d" className="input-container">
          <div className="label-container">
            <div className="label-subcontainer">
              <div className="sub-label-form">discount code</div>
            </div>
          </div>
          <div className="form-block-input w-form">
            <div
              id="email-form"
              name="email-form"
              data-name="Email Form"
              className="form-input select-field"
            >
              <div className="select-field-wrapper">
                <Controller
                  rules={{
                    required: true,
                    minLength: { value: 4, message: 'Min lengh is 4.' },
                  }}
                  name="code"
                  control={control}
                  defaultValue={currentDiscountCode ?? ''}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                      id="code"
                      className="text-field-2 font-size-14 w-input"
                      placeholder={currentDiscountCode ?? '0000 - 0000 - 0000'}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          className={cn('button secondary w-inline-block', {
            disabled: !watchCode,
          })}
          disabled={!watchCode}
        >
          <div>Apply</div>
        </button>
      </div>
      {errors?.code?.message && <ErrorMessage errors={errors?.code?.message} />}
    </form>
  )
}

export default DiscountCode
