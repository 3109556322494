import { useState } from 'react'
import cn from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { ReactComponent as LocationIcon } from '../../../images/icons/location.svg'
import { ReactComponent as BussinesIcon } from '../../../images/icons/bussines.svg'
import { ReactComponent as EnvelopeIcon } from '../../../images/icons/envelope.svg'
import { ReactComponent as CircleIcon } from '../../../images/icons/circle.svg'
import { ReactComponent as PersonIcon } from '../../../images/icons/NavDrawer/account.svg'

const Details = ({ attributes }) => {
  const [showBoutiqueNotes, setShowBoutiqueNotes] = useState(false)
  return (
    <div data-w-id="82b336d5-eb93-e91b-f6b6-1b03701daffe" className="order-2nd-div">
      <div className="w-layout-grid main-info-block-container">
        <div className="info-block-container">
          <div>
            <h2 className="heading-title">{attributes.orderName}</h2>
            <div className="w-layout-grid main-grid-info-order">
              <div className="w-layout-grid grid-info-order">
                <div className="div-block-108-copy">
                  <div className="text-details-container">
                    <div className="text-bold">Photographer:</div>
                  </div>
                </div>
                <div className="w-layout-grid grid-23 main _2">
                  <div className="icon small x2 w-embed">
                    <PersonIcon />
                  </div>
                  <div>{attributes?.photographerName ?? '-'}</div>
                </div>
                <div className="w-layout-grid grid-23 main _2">
                  <div className="icon small x2 w-embed">
                    <BussinesIcon />
                  </div>
                  <div>{attributes?.photographerBusinessName ?? '-'}</div>
                </div>
                <div className="w-layout-grid grid-23 main _2">
                  <div className="icon small x2 w-embed">
                    <EnvelopeIcon />
                  </div>
                  <div>{attributes?.photographerEmail ?? '-'}</div>
                </div>
              </div>
              <div className="w-layout-grid grid-info-order">
                <div className="div-block-108-copy">
                  <div className="text-details-container">
                    <div className="text-bold">Shipping to:</div>
                  </div>
                </div>
                <div className="w-layout-grid grid-23 main _2">
                  <div className="icon small x2 w-embed">
                    <PersonIcon />
                  </div>
                  <div>{attributes?.clientName ?? '-'}</div>
                </div>
                <div className="w-layout-grid grid-23 main _2">
                  <div
                    id="w-node-_44b07485-3d4e-1b08-3415-2cdb0066d9be-fde80a15"
                    className="icon small x2 w-embed"
                  >
                    <LocationIcon />
                  </div>
                  {!isEmpty(attributes.shippingAddress) ? (
                    <div>
                      {attributes.shippingAddress.street} <br />
                      {`${attributes.shippingAddress.city}, ${attributes.shippingAddress.stateProvince} ${attributes.shippingAddress.zip}`}
                    </div>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="div-notes hide">
          <div data-duration-in="300" data-duration-out="100" className="tabs-2 w-tabs">
            <div className="tabs-menu client w-tab-menu" role="tablist">
              <a
                data-w-tab="Tab 1"
                className={cn('tab-link-tab-1 w-inline-block w-tab-link ', {
                  'w--current': !showBoutiqueNotes,
                })}
                id="w-tabs-0-data-w-tab-0"
                href="#w-tabs-0-data-w-pane-0"
                role="tab"
                aria-controls="w-tabs-0-data-w-pane-0"
                aria-selected="true"
                onClick={() => setShowBoutiqueNotes(false)}
              >
                <div className="icon smaller right-margin w-embed">
                  <CircleIcon />
                </div>
                <div>Client notes</div>
              </a>
              <a
                data-w-tab="Tab 4"
                className={cn('tab-link-tab-1 boutique w-inline-block w-tab-link', {
                  'w--current': showBoutiqueNotes,
                })}
                id="w-tabs-0-data-w-tab-1"
                href="#w-tabs-0-data-w-pane-1"
                role="tab"
                aria-controls="w-tabs-0-data-w-pane-1"
                aria-selected="false"
                tabIndex="-1"
                onClick={() => setShowBoutiqueNotes(true)}
              >
                <div className="icon smaller right-margin w-embed">
                  <CircleIcon />
                </div>
                <div>Boutique Notes</div>
              </a>
            </div>
            <div className="tabs-content-2 w-tab-content">
              <div
                data-w-tab="Tab 1"
                className={cn('tab-pane-tab-2 boutique w-tab-pane', {
                  'w--tab-active': !showBoutiqueNotes,
                })}
                id="w-tabs-0-data-w-pane-0"
                role="tabpanel"
                aria-labelledby="w-tabs-0-data-w-tab-0"
                style={{ opacity: showBoutiqueNotes ? 0 : 1, transition: 'opacity 300ms ease 0s;' }}
              >
                <div className="gray-block">
                  <div className="paragraph-container client">
                    <p>{attributes.notes}</p>
                  </div>
                </div>
              </div>
              <div
                data-w-tab="Tab 4"
                className={cn('tab-pane-tab-2 boutique w-tab-pane', {
                  'w--tab-active': showBoutiqueNotes,
                })}
                id="w-tabs-0-data-w-pane-1"
                role="tabpanel"
                aria-labelledby="w-tabs-0-data-w-tab-1"
              >
                <div className="gray-block">
                  <div className="paragraph-container boutique">
                    <p>{attributes.boutiqueShippingNote}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-layout-grid grid-38">
          <div className="div-block-143">
            <div className="tab-link-tab-1-copy active">
              <div className="text-details-container">
                <div className="icon smaller right-margin w-embed">
                  <CircleIcon />
                </div>
                <div>Client Notes</div>
              </div>
            </div>
            <div className="tab-pane-tab-2-copy">
              <div className="gray-block">
                <p>{attributes.notes}</p>
              </div>
            </div>
          </div>
          <div className="div-block-143">
            <div className="tab-link-tab-1-copy boutique active">
              <div className="text-details-container">
                <div className="icon smaller right-margin w-embed">
                  <CircleIcon />
                </div>
                <div>Boutique Notes</div>
              </div>
            </div>
            <div className="tab-pane-tab-2-copy">
              <div className="gray-block">
                <div className="paragraph-container boutique">
                  <p>{attributes.boutiqueShippingNote}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Details
