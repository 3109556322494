import { ReactComponent as ProgressIcon } from '../../images/icons/progress.svg'
import { ReactComponent as TrophyIcon } from '../../images/icons/trophy.svg'

const PhotographerProgress = () => (
  <div className="client-account-block2">
    <div className="accordion-item-block no-margin">
      <div className="sub-heading-container both-edges">
        <div className="div-block-278">
          <div className="icon right-margin w-embed">
            <ProgressIcon />
          </div>
          <h2 className="heading-title-2">Progress</h2>
        </div>
      </div>
    </div>

    <div className="w-layout-grid progress-grid">
      <div className="w-layout-grid number-indicator-grid">
        <div className="progress-block">
          <div className="number-indicator">1</div>
        </div>
        <div className="progress-block">
          <div className="number-indicator">2</div>
        </div>
        <div className="progress-block">
          <div className="number-indicator">3</div>
        </div>
        <div className="progress-block">
          <div className="icon small x2 top-margin w-embed">
            <TrophyIcon />
          </div>
        </div>
      </div>
      <div className="div-block-286">
        <div className="w-layout-grid progress-subgrid">
          <div className="progress-div" />
          <div className="progress-div" />
          <div className="progress-div" />
          <div className="progress-div empty" />
        </div>
      </div>
      <div className="w-layout-grid number-indicator-grid">
        <div className="progress-block">
          <div>New Account</div>
        </div>
        <div className="progress-block">
          <div>Test Prints</div>
        </div>
        <div className="progress-block">
          <div>Studio Samples</div>
        </div>
        <div className="progress-block">
          <div>$1000 on Sales</div>
        </div>
      </div>
    </div>
  </div>
)

export default PhotographerProgress
