import { useState, useEffect, useCallback } from 'react'

import httpClient from '../httpClient'
import { apiErrorMessage } from '../lib/utils'

const useOrders = () => {
  const [loading, setLoading] = useState(true)
  const [error, setApiError] = useState(null)
  const [orders, setOrders] = useState(null)
  const [metadata, setMetadata] = useState(null)
  const [order, setOrder] = useState(null)

  const fetchOrders = useCallback(async () => {
    try {
      setLoading('Loading orders')
      setApiError(null)
      const { data, meta } = await httpClient('lab_orders')
      setOrders(data)
      setMetadata(meta)
      setLoading(false)
    } catch (err) {
      setApiError(apiErrorMessage(err))
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchOrders()
  }, [])

  const searchOrders = useCallback(async (query) => {
    try {
      setLoading('Loading orders')
      setApiError(null)
      const { data, meta } = await httpClient(`lab_orders/search?${query}`)
      setOrders(data)
      setMetadata(meta)
      setLoading(false)
    } catch (err) {
      setApiError(apiErrorMessage(err))
      setLoading(false)
    }
  }, [])

  const getOrder = useCallback(async (id) => {
    console.log(`getting ${id}`)
    setLoading(true)
    try {
      const data = await httpClient(`lab_orders/${id}`)
      setOrder(data)
      setLoading(false)
    } catch (err) {
      setApiError(apiErrorMessage(error))
      setLoading(false)
    }
  }, [])

  return {
    orders,
    error,
    loading,
    refetchOrders: fetchOrders,
    getOrder,
    order,
    metadata,
    searchOrders,
  }
}

export default useOrders
