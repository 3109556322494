import { dollarTemplate } from '../../../lib/utils'

const ReferralReportItem = ({ referral }) => (
  <div className="client-credit-table-element">
    <div className="client-credit-table-content">
      <div>{referral?.name}</div>
    </div>
    <div className="client-credit-table-content">
      <div>{referral?.businessName}</div>
    </div>
    <div className="client-credit-table-content">
      <div>{referral?.email}</div>
    </div>
    <div className="client-credit-table-content credit-earned">
      <div>{dollarTemplate(referral?.creditEarned)}</div>
    </div>
  </div>
)

export default ReferralReportItem
