/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query'
import { useLocation, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import Cropper, { ReactCropperElement } from 'react-cropper'
import { useCallback, useEffect, useState, useRef } from 'react'
import httpClient from '../httpClient'
import { useLottie } from '../hooks'
import animationData from '../animations/orderName.json'
import Loading from '../components/common/Loading'
import { dollarTemplate } from '../lib/utils'
import SortImages from '../components/SortImages'
import ChevronIcon from '../images/chevron-down-12.svg'
import albanyIcon from '../images/contemporary_frames/ALBANY-84126_7:8in.jpg'
import styles from './ProductSelection.module.css'
import Modal from '../components/common/Modals/BaseModal'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css'

// TODO: I need to implement form errors to be shown as a popup || DOING
// TODO there's an attribute called "images_in_cart", those are the ones that should have the cart icon
// TODO: multiple page form data should be stored in form state. and form state should be an object with photo ids as keys
// TODO: form needs to be able to populate from form state. and state needs to be able to populate from URL params
// TODO: form state needs to be updated when form is submitted (remove photo id from form state)

const ProductSelection = () => {
  const location = useLocation()
  const { id } = useParams()
  const [photos, setPhotos] = useState([])
  const [selectedPhoto, setSelectedPhoto] = useState('')
  const [subtotal, setSubtotal] = useState(0)
  const [ModalIsOpen, setModalIsOpen] = useState(false)
  const [photosLoadingMessage, setPhotosLoadingMessage] = useState('Photos are loading')
  const quantityRef = useRef(1)
  const notesRef = useRef('')

  const defaultOptions = {
    meta: {
      subtotal: 0,
      xAspectRatio: undefined,
      yAspectRatio: undefined,
      aspectRatio: undefined,
      cropPositionX: undefined,
      cropPositionY: undefined,
      cropWidth: undefined,
      cropHeight: undefined,
      finishingCategoryId: undefined,
      finishingServiceId: undefined,
      finishingDetailId: undefined,
      requiredInputs: ['Paper Type', 'Print Size', 'Finishing Service'],
    },
    paperType: {
      label: 'Paper type',
      options: [],
      selectedValue: undefined,
      selectedValuePrice: 0,
      isLoading: true,
      isDisabled: false,
      disabledMessage: '',
      style: {
        gridColumn: '2 / 5',
      },
    },
    printSize: {
      label: 'Print size',
      options: [],
      selectedValue: undefined,
      selectedValuePrice: 0,
      isLoading: false,
      isDisabled: true,
      disabledMessage: 'Please select a paper type',
      style: {
        gridColumn: 'span 2',
      },
    },
    finishingService: {
      label: 'Finishing service',
      options: [],
      selectedValue: undefined,
      selectedValuePrice: 0,
      isLoading: false,
      isDisabled: true,
      disabledMessage: 'Please select a paper type and print size',
      style: {
        gridColumn: 'span 2',
      },
    },
    matFormat: {
      label: 'Mat format',
      options: [],
      selectedValue: undefined,
      selectedValuePrice: 0,
      isLoading: false,
      isDisabled: true,
      disabledMessage: 'Please select a finishing service',
      style: {
        gridColumn: 'span 2',
      },
    },
    matSize: {
      label: 'Mat size',
      options: [],
      selectedValue: undefined,
      selectedValuePrice: 0,
      isLoading: false,
      isDisabled: true,
      disabledMessage: 'Please select a mat format',
      style: {
        gridColumn: 'span 2',
      },
    },
    mountSize: {
      label: 'Mount size',
      options: [],
      selectedValue: undefined,
      selectedValuePrice: 0,
      isLoading: false,
      isDisabled: true,
      disabledMessage: 'Please select a finishing service',
      style: {
        gridColumn: 'span 2',
      },
    },
    mountColor: {
      label: 'Mount color',
      options: [],
      selectedValue: undefined,
      selectedValuePrice: 0,
      isLoading: false,
      isDisabled: true,
      disabledMessage: 'Please select a mount size',
      style: {
        gridColumn: 'span 2',
      },
    },
    frameType: {
      label: 'Frame type',
      options: [],
      selectedValue: undefined,
      selectedValuePrice: 0,
      isLoading: false,
      isDisabled: true,
      disabledMessage: 'Please select a finishing service',
      style: {
        gridColumn: 'span 4',
      },
    },
    frame: {
      label: 'Frame',
      options: [],
      selectedValue: undefined,
      selectedValuePrice: 0,
      isLoading: false,
      isDisabled: true,
      disabledMessage: 'Please select a frame type',
      style: {
        gridColumn: 'span 2',
      },
    },
    frameMat: {
      label: 'Frame mat',
      options: [],
      selectedValue: undefined,
      selectedValuePrice: 0,
      isLoading: false,
      isDisabled: true,
      disabledMessage: 'Please select a frame type',
      style: {
        gridColumn: 'span 2',
      },
    },
    acrylic: {
      label: 'Acrylic',
      options: [],
      selectedValue: undefined,
      selectedValuePrice: 0,
      isLoading: false,
      isDisabled: true,
      disabledMessage: 'Please select a frame type',
      style: {
        gridColumn: 'span 2',
      },
    },
    frameStain: {
      label: 'Frame stain',
      options: [],
      selectedValue: undefined,
      selectedValuePrice: 0,
      isLoading: false,
      isDisabled: true,
      disabledMessage: 'Please select a frame type',
      style: {
        gridColumn: 'span 2',
      },
    },
  }

  const lottieOptions = {
    animationData,
    loop: true,
    autoplay: true,
    renderer: 'svg',
    direction: 1,
    duration: 0,
  }

  const [, arrowContainer] = useLottie(lottieOptions)

  const [form, setForm] = useState({
    meta: defaultOptions.meta,
    inputs: {
      paperType: defaultOptions.paperType,
      printSize: defaultOptions.printSize,
      finishingService: defaultOptions.finishingService,
    },
  })

  useEffect(() => {
    setForm((prevState) => {
      const newState = { ...prevState }
      if (newState.meta.requiredInputs === undefined || newState.meta.requiredInputs.length === 0) {
        newState.meta.reviewOrderError = undefined
      } else {
        newState.meta.reviewOrderError = `To add this item to your cart, please select ${prevState.meta.requiredInputs.join(', ')}. You can set finishing category to NONE to add to cart.`
      }
      return newState
    })
  }, [form.meta.requiredInputs])

  const cropperRef = useRef(null);
  const onCrop = (event) => {
    setForm((prevState) => ({
      ...prevState,
      meta: {
        ...prevState.meta,
        cropPositionX: event.detail.x,
        cropPositionY: event.detail.y,
        cropWidth: event.detail.width,
        cropHeight: event.detail.height,
      },
    }))
  };

  useEffect(() => {
    if (selectedPhoto === '') return
    const cropper = cropperRef.current?.cropper;
    if (form.meta.aspectRatio !== undefined) {
      // Show crop box UI
      cropper.crop();
      // Change crop box aspect ratio
      cropper.setAspectRatio(form.meta.aspectRatio);
    } else {
      // Hide crop box UI
      cropper.clear()
    }
  }, [form.meta.aspectRatio])

  useEffect(() => {
    if (form.meta.xAspectRatio === undefined || form.meta.yAspectRatio === undefined) return

    setForm((prevState) => ({
      ...prevState,
      meta: {
        ...prevState.meta,
        aspectRatio: prevState.meta.xAspectRatio / prevState.meta.yAspectRatio,
      },
    }))
  }, [form.meta.xAspectRatio, form.meta.yAspectRatio])

  const handleCropBoxRotate = () => {
    setForm((prevState) => ({
      ...prevState,
      meta: {
        ...prevState.meta,
        xAspectRatio: prevState.meta.yAspectRatio,
        yAspectRatio: prevState.meta.xAspectRatio,
      },
    }))
  }

  // Subtotal value configuration
  useEffect(() => {
    const inputs = [
      form.inputs.printSize?.selectedValuePrice,
      form.inputs.mountSize?.selectedValuePrice,
      form.inputs.matSize?.selectedValuePrice,
    ]
    let sum = 0

    inputs.forEach((input) => {
      const floatValue = parseFloat(input)
      if (!Number.isNaN(floatValue)) {
        sum += floatValue
      }
    })
    setSubtotal(sum.toFixed(2))
  }, [
    form.inputs.printSize?.selectedValuePrice,
    form.inputs.mountSize?.selectedValuePrice,
    form.inputs.matSize?.selectedValuePrice,
  ])

  const logOrder = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('location: ', location)
  }, [location])

  useEffect(() => {
    logOrder()
  }, [logOrder])

  const resetSelectedOptions = () => {
    setForm({
      meta: defaultOptions.meta,
      inputs: {
        paperType: {
          ...defaultOptions.paperType,
          options: form.inputs.paperType.options,
          isLoading: false,
          isDisabled: false,
        },
        printSize: defaultOptions.printSize,
        finishingService: defaultOptions.finishingService,
      },
    })
  }

  const photosQuery = useQuery({
    queryKey: ['photos', id],
    queryFn: () => httpClient.get(`lab_orders/${id}/lab_order_photographs`),
    staleTime: Infinity,
    cacheTime: 0,
  })

  useEffect(() => {
    if (photosQuery.isError) {
      // TODO: Add error handling
    } else if (photosQuery.isLoading) {
      setPhotosLoadingMessage('Photos are loading')
    } else if (photosQuery.isSuccess) {
      setPhotos(photosQuery.data.data)
      setSelectedPhoto(photosQuery.data.data[0])
      setPhotosLoadingMessage(false)
    }
  }, [photosQuery.isSuccess])

  useQuery({
    queryKey: ['labOrder', id],
    queryFn: () => httpClient.get(`lab_orders/${id}/retrieve_full_order`),
    onSuccess: ({ data }) => {
      const { imagesInCart } = data.attributes
      const photosWithCartIcon = photos.map((photo) => {
        if (imagesInCart.includes(photo.attributes.guid)) {
          return {
            ...photo,
            attributes: {
              ...photo.attributes,
              inCart: true,
            },
          }
        }
        return photo
      })
      setPhotos(photosWithCartIcon)
    },
    staleTime: Infinity,
    cacheTime: 0,
  })

  function handleSelectedPhoto(photo) {
    setSelectedPhoto(photo)
    if (photo !== selectedPhoto) {
      resetSelectedOptions()
    }
  }

  const handleChangePhotoOrder = (images) => {
    setPhotos(images)
  }

  const paperTypeOptionsQuery = useQuery({
    queryKey: ['paperTypeOptions'],
    queryFn: () => httpClient.get('products/papers'),
    staleTime: Infinity,
    cacheTime: 0,
  })

  useEffect(() => {
    if (paperTypeOptionsQuery.isError) {
      // TODO: Add error handling
    } else if (paperTypeOptionsQuery.isSuccess) {
      const paperTypeOptions = paperTypeOptionsQuery.data.data.map((paperType) => ({
        value: paperType.id,
        text: paperType.attributes.name,
      }))

      const sortedPaperTypeOptions = paperTypeOptions.sort((a, b) => {
        if (a.text < b.text) {
          return -1
        }
        if (a.text > b.text) {
          return 1
        }
        return 0
      }, [])

      setForm((prevState) => ({
        meta: defaultOptions.meta,
        inputs: {
          paperType: {
            ...prevState.inputs.paperType,
            options: sortedPaperTypeOptions,
            isLoading: false,
          },
          printSize: defaultOptions.printSize,
          finishingService: defaultOptions.finishingService,
        },
      }))
    }
  }, [paperTypeOptionsQuery.isSuccess])

  const printSizeOptionsQuery = useQuery({
    queryKey: ['printSizeOptions', form.inputs.paperType.selectedValue],
    queryFn: () => httpClient.get(`products/${form.inputs.paperType.selectedValue}/print_sizes`),
    enabled: !!form.inputs.paperType?.selectedValue && form.inputs.paperType?.selectedValue !== 'default',
    staleTime: Infinity,
    cacheTime: 0,
  })

  useEffect(() => {
    if (form.inputs.paperType.selectedValue === 'default') {
      setForm({
        meta: defaultOptions.meta,
        inputs: {
          paperType: {
            ...defaultOptions.paperType,
            options: form.inputs.paperType.options,
            isLoading: false,
            isDisabled: false,
          },
          printSize: defaultOptions.printSize,
          finishingService: defaultOptions.finishingService,
        },
      })
    } else if (printSizeOptionsQuery.isInitialLoading) {
      setForm((prevState) => {
        const newState = { ...prevState }
        newState.inputs.printSize.isLoading = true
        return newState
      })
    } else if (printSizeOptionsQuery.isSuccess) {
      const sortedPrintSizes = printSizeOptionsQuery.data.sort((a, b) => {
        if (Number(a.xAspect) < Number(b.xAspect)) {
          return -1
        }
        if (Number(a.xAspect) > Number(b.xAspect)) {
          return 1
        }
        if (Number(a.yAspect) < Number(b.yAspect)) {
          return -1
        }
        if (Number(a.yAspect) > Number(b.yAspect)) {
          return 1
        }
        return 0
      }, [])

      const sortedPrintSizeOptions = sortedPrintSizes.map((printSize) => ({
        value: printSize.guid,
        text: `${printSize.name} (${dollarTemplate(printSize.price)})`,
        price: printSize.price,
        xAspectRatio: printSize.xAspect,
        yAspectRatio: printSize.yAspect,
      }))

      setForm((prevState) => ({
        meta: {
          ...defaultOptions.meta,
          paperTypeId: prevState.meta.paperTypeId,
        },
        inputs: {
          paperType: prevState.inputs.paperType,
          printSize: {
            ...defaultOptions.printSize,
            options: sortedPrintSizeOptions,
            isLoading: false,
            isDisabled: false,
          },
          finishingService: defaultOptions.finishingService,
        },
      }))
    } else if (printSizeOptionsQuery.isError) {
      // TODO: handle error
    }
  }, [form.inputs.paperType?.selectedValue, printSizeOptionsQuery.isInitialLoading])

  useEffect(() => {
    setForm((prevState) => ({
      meta: {
        ...defaultOptions.meta,
        paperTypeId: prevState.meta.paperTypeId,
        xAspectRatio: prevState.meta.xAspectRatio,
        yAspectRatio: prevState.meta.yAspectRatio,
        aspectRatio: prevState.meta.aspectRatio,
      },
      inputs: {
        paperType: prevState.inputs.paperType,
        printSize: prevState.inputs.printSize,
        finishingService: defaultOptions.finishingService,
      },
    }))
  }, [form.inputs.printSize.selectedValue])

  const finishingCategoriesQuery = useQuery({
    queryKey: ['finishingCategories', form.inputs.printSize.selectedValue],
    queryFn: () =>
      httpClient.get(`products/print_sizes/${form.inputs.printSize.selectedValue}/finishing_categories`),
    enabled: !!form.inputs.printSize?.selectedValue && form.inputs.printSize?.selectedValue !== 'default',
    staleTime: Infinity,
    cacheTime: 0,
  })

  useEffect(() => {
    if (finishingCategoriesQuery.isInitialLoading) {
      setForm((prevState) => {
        const newState = { ...prevState }
        newState.inputs.finishingService.isLoading = true
        return newState
      })
    } else if (finishingCategoriesQuery.isSuccess) {
      const finishingCategories = finishingCategoriesQuery.data.data.map((category) => ({
        value: category.attributes.guid,
        text: category.attributes.finishingCategoryPrimaryName,
      }))
      finishingCategories.push({ value: 'None', text: 'None' })

      const uniqueFinishingCategories = Array.from(
        finishingCategories.reduce((map, obj) => map.set(obj.text, obj), new Map()).values(),
      )

      setForm((prevState) => {
        const newState = { ...prevState }
        newState.inputs.finishingService.options = uniqueFinishingCategories
        newState.inputs.finishingService.isLoading = false
        newState.inputs.finishingService.isDisabled = false
        return newState
      })
    } else if (finishingCategoriesQuery.isError) {
      // TODO: handle error
    }
  }, [finishingCategoriesQuery.isInitialLoading])

  useEffect(() => {
    if (form.inputs.finishingService.selectedValueText === undefined) return
    if (form.inputs.finishingService.selectedValueText === 'Mounting') {
      setForm((prevState) => ({
        meta: {
          subtotal: prevState.meta.subtotal,
          xAspectRatio: prevState.meta.xAspectRatio,
          yAspectRatio: prevState.meta.yAspectRatio,
          aspectRatio: prevState.meta.aspectRatio,
          cropPositionX: prevState.meta.cropPositionX,
          cropPositionY: prevState.meta.cropPositionY,
          cropWidth: prevState.meta.cropWidth,
          cropHeight: prevState.meta.cropHeight,
          finishingCategoryId: form.inputs.finishingService.selectedValue,
          requiredInputs: ['Mount Size', 'Mount Color'],
        },
        inputs: {
          paperType: prevState.inputs.paperType,
          printSize: prevState.inputs.printSize,
          finishingService: prevState.inputs.finishingService,
        },
      }))
      return
    }
    if (form.inputs.finishingService.selectedValueText === 'None') {
      setForm((prevState) => ({
        meta: {
          subtotal: prevState.meta.subtotal,
          xAspectRatio: prevState.meta.xAspectRatio,
          yAspectRatio: prevState.meta.yAspectRatio,
          aspectRatio: prevState.meta.aspectRatio,
          cropPositionX: prevState.meta.cropPositionX,
          cropPositionY: prevState.meta.cropPositionY,
          cropWidth: prevState.meta.cropWidth,
          cropHeight: prevState.meta.cropHeight,
          finishingCategoryId: undefined,
          requiredInputs: [],
        },
        inputs: {
          paperType: prevState.inputs.paperType,
          printSize: prevState.inputs.printSize,
          finishingService: prevState.inputs.finishingService,
        },
      }))
      return
    }

    const finishingSubcategories = finishingCategoriesQuery.data.data
      .filter(
        (finishingCategory) =>
          finishingCategory.attributes.finishingCategoryPrimaryName ===
          form.inputs.finishingService.selectedValueText,
      )
      .map((finishingCategory) => ({
        value: finishingCategory.attributes.guid,
        text: finishingCategory.attributes.finishingCategoryDetailName,
      }))

    setForm((prevState) => {
      const newState = {
        meta: {
          subtotal: prevState.meta.subtotal,
          xAspectRatio: prevState.meta.xAspectRatio,
          yAspectRatio: prevState.meta.yAspectRatio,
          aspectRatio: prevState.meta.aspectRatio,
          cropPositionX: prevState.meta.cropPositionX,
          cropPositionY: prevState.meta.cropPositionY,
          cropWidth: prevState.meta.cropWidth,
          cropHeight: prevState.meta.cropHeight,
          finishingCategoryId: undefined,
        },
        inputs: {
          paperType: prevState.inputs.paperType,
          printSize: prevState.inputs.printSize,
          finishingService: prevState.inputs.finishingService,
        },
      }
      if (prevState.inputs.finishingService.selectedValueText === 'Matting') {
        newState.meta.requiredInputs = ['Mat Format', 'Mat Size']
        newState.inputs = {
          ...newState.inputs,
          matFormat: {
            ...defaultOptions.matFormat,
            options: finishingSubcategories,
            isLoading: false,
            isDisabled: false,
          },
          matSize: {
            ...defaultOptions.matSize,
            isLoading: false,
            isDisabled: true,
          },
        }
      } else if (prevState.inputs.finishingService.selectedValueText === 'Framing') {
        newState.meta.requiredInputs = ['Frame Type', 'Frame', 'Frame Mat', 'Acrylic', 'Frame Stain']
        newState.inputs = {
          ...newState.inputs,
          frameType: {
            ...defaultOptions.frameType,
            options: finishingSubcategories,
            isLoading: false,
            isDisabled: false,
          },
        }
      }
      return newState
    })
  }, [form.inputs.finishingService.selectedValueText])

  useEffect(() => {
    if (form.inputs.matFormat?.selectedValue === undefined && form.inputs.frameType?.selectedValue === undefined) return
    let finishingCategoryId
    let error

    if (form.inputs.matFormat?.selectedValue !== undefined) {
      finishingCategoryId = form.inputs.matFormat.selectedValue
      error = 'Please fill Mat Size'
    } else if (form.inputs.frameType?.selectedValue !== undefined) {
      finishingCategoryId = form.inputs.frameType.selectedValue
      error = 'Please fill Frame, Frame Mat, Acrylic and Frame Stain'
    }

    setForm((prevState) => ({
      meta: {
        subtotal: prevState.meta.subtotal,
        xAspectRatio: prevState.meta.xAspectRatio,
        yAspectRatio: prevState.meta.yAspectRatio,
        aspectRatio: prevState.meta.aspectRatio,
        cropPositionX: prevState.meta.cropPositionX,
        cropPositionY: prevState.meta.cropPositionY,
        cropWidth: prevState.meta.cropWidth,
        cropHeight: prevState.meta.cropHeight,
        finishingCategoryId,
        error,
      },
      inputs: prevState.inputs,
    }))
  }, [form.inputs.matFormat?.selectedValue, form.inputs.frameType?.selectedValue])

  const finishingServicesQuery = useQuery({
    queryKey: ['finishingServices', form.meta.finishingCategoryId],
    queryFn: () =>
      httpClient.get(
        `products/print_sizes/${form.inputs.printSize.selectedValue}/finishing_categories/${form.meta.finishingCategoryId}/finishing_services`,
      ),
    enabled: !!form.meta.finishingCategoryId,
    staleTime: Infinity,
    cacheTime: 0,
  })

  useEffect(() => {
    if (finishingServicesQuery.isError) {
      // TODO: handle error
    } else if (finishingServicesQuery.isInitialLoading) {
      if (form.inputs.finishingService.selectedValueText === 'Framing') {
        setForm((prevState) => ({
          meta: {
            subtotal: prevState.meta.subtotal,
            xAspectRatio: prevState.meta.xAspectRatio,
            yAspectRatio: prevState.meta.yAspectRatio,
            aspectRatio: prevState.meta.aspectRatio,
            cropPositionX: prevState.meta.cropPositionX,
            cropPositionY: prevState.meta.cropPositionY,
            cropWidth: prevState.meta.cropWidth,
            cropHeight: prevState.meta.cropHeight,
            finishingCategoryId: prevState.meta.finishingCategoryId,
            requiredInputs: ['Frame Type', 'Frame', 'Frame Mat', 'Acrylic', 'Frame Stain'],
          },
          inputs: {
            paperType: prevState.inputs.paperType,
            printSize: prevState.inputs.printSize,
            finishingService: prevState.inputs.finishingService,
            frameType: prevState.inputs.frameType,
            frame: {
              ...defaultOptions.frame,
              isLoading: true,
              isDisabled: true,
            },
            frameMat: defaultOptions.frameMat,
            acrylic: defaultOptions.acrylic,
            frameStain: defaultOptions.frameStain,
          },
        }))
      } else if (form.inputs.finishingService.selectedValueText === 'Matting') {
        setForm((prevState) => ({
          meta: {
            subtotal: prevState.meta.subtotal,
            xAspectRatio: prevState.meta.xAspectRatio,
            yAspectRatio: prevState.meta.yAspectRatio,
            aspectRatio: prevState.meta.aspectRatio,
            cropPositionX: prevState.meta.cropPositionX,
            cropPositionY: prevState.meta.cropPositionY,
            cropWidth: prevState.meta.cropWidth,
            cropHeight: prevState.meta.cropHeight,
            finishingCategoryId: prevState.meta.finishingCategoryId,
            requiredInputs: ['Mat Format', 'Mat Size'],
          },
          inputs: {
            paperType: prevState.inputs.paperType,
            printSize: prevState.inputs.printSize,
            finishingService: prevState.inputs.finishingService,
            matFormat: prevState.inputs.matFormat,
            matSize: {
              ...defaultOptions.matSize,
              isLoading: true,
              isDisabled: true,
            },
          },
        }))
      } else if (form.inputs.finishingService.selectedValueText === 'Mounting') {
        setForm((prevState) => ({
          meta: {
            subtotal: prevState.meta.subtotal,
            xAspectRatio: prevState.meta.xAspectRatio,
            yAspectRatio: prevState.meta.yAspectRatio,
            aspectRatio: prevState.meta.aspectRatio,
            cropPositionX: prevState.meta.cropPositionX,
            cropPositionY: prevState.meta.cropPositionY,
            cropWidth: prevState.meta.cropWidth,
            cropHeight: prevState.meta.cropHeight,
            finishingCategoryId: prevState.meta.finishingCategoryId,
            requiredInputs: ['Mount Size', 'Mount Color'],
          },
          inputs: {
            paperType: prevState.inputs.paperType,
            printSize: prevState.inputs.printSize,
            finishingService: prevState.inputs.finishingService,
            mountSize: {
              ...defaultOptions.mountSize,
              isLoading: true,
              isDisabled: true,
            },
            mountColor: defaultOptions.mountColor,
          },
        }))
      }
    } else if (finishingServicesQuery.isSuccess) {
      if (form.inputs.finishingService.selectedValueText === 'Framing') {
        let frames
        if (form.inputs.frameType.selectedValueText === 'Contemporary Frame') {
          frames = finishingServicesQuery.data.data.map((service) => service.attributes.frameWoodPricing)
        } else if (form.inputs.frameType.selectedValueText === 'Hardwood Frame') {
          frames = finishingServicesQuery.data.data.map((service) => service.attributes.frameWoodAndProfileName)
        }

        const uniqueFrames = [...new Set(frames)]

        const sortedUniqueFrames = uniqueFrames.sort((a, b) => {
          if (a < b) {
            return -1
          }
          if (a > b) {
            return 1
          }
          return 0
        }, [])

        setForm((prevState) => ({
          meta: {
            subtotal: prevState.meta.subtotal,
            xAspectRatio: prevState.meta.xAspectRatio,
            yAspectRatio: prevState.meta.yAspectRatio,
            aspectRatio: prevState.meta.aspectRatio,
            cropPositionX: prevState.meta.cropPositionX,
            cropPositionY: prevState.meta.cropPositionY,
            cropWidth: prevState.meta.cropWidth,
            cropHeight: prevState.meta.cropHeight,
            finishingCategoryId: prevState.meta.finishingCategoryId,
            requiredInputs: ['Frame Type', 'Frame', 'Frame Mat', 'Acrylic', 'Frame Stain'],
          },
          inputs: {
            paperType: prevState.inputs.paperType,
            printSize: prevState.inputs.printSize,
            finishingService: prevState.inputs.finishingService,
            frameType: prevState.inputs.frameType,
            frame: {
              ...defaultOptions.frame,
              options: sortedUniqueFrames.map((frame) => ({
                value: frame,
                text: frame,
              })),
              isLoading: false,
              isDisabled: false,
            },
            frameMat: defaultOptions.frameMat,
            acrylic: defaultOptions.acrylic,
            frameStain: defaultOptions.frameStain,
          },
        }))
      } else if (form.inputs.finishingService.selectedValueText === 'Matting') {
        const matSizes = finishingServicesQuery.data.data
          .filter((service) =>
            service.attributes.finishingCategory.includes(
              form.inputs.matFormat.selectedValueText,
            ))
          .map((service) => ({
            value: service.id,
            text: `${service.attributes.name} ($${service.attributes.price})`,
            price: service.attributes.price,
          }))

        const sortedMatSizes = matSizes.sort((a, b) => {
          if (a.text < b.text) {
            return -1
          }
          if (a.text > b.text) {
            return 1
          }
          return 0
        }, [])

        setForm((prevState) => ({
          meta: {
            subtotal: prevState.meta.subtotal,
            xAspectRatio: prevState.meta.xAspectRatio,
            yAspectRatio: prevState.meta.yAspectRatio,
            aspectRatio: prevState.meta.aspectRatio,
            cropPositionX: prevState.meta.cropPositionX,
            cropPositionY: prevState.meta.cropPositionY,
            cropWidth: prevState.meta.cropWidth,
            cropHeight: prevState.meta.cropHeight,
            finishingCategoryId: prevState.meta.finishingCategoryId,
            requiredInputs: ['Mat Size'],
          },
          inputs: {
            paperType: prevState.inputs.paperType,
            printSize: prevState.inputs.printSize,
            finishingService: prevState.inputs.finishingService,
            matFormat: prevState.inputs.matFormat,
            matSize: {
              ...defaultOptions.matSize,
              options: sortedMatSizes,
              isLoading: false,
              isDisabled: false,
            },
          },
        }))
      } else if (form.inputs.finishingService.selectedValueText === 'Mounting') {
        const mountSizes = finishingServicesQuery.data.data
          .filter((service) => service.attributes.finishingCategory === 'Mount')
          .map((service) => ({
            value: service.id,
            text: `${service.attributes.name} ($${service.attributes.price})`,
            price: service.attributes.price,
          }))

        setForm((prevState) => ({
          meta: {
            subtotal: prevState.meta.subtotal,
            xAspectRatio: prevState.meta.xAspectRatio,
            yAspectRatio: prevState.meta.yAspectRatio,
            aspectRatio: prevState.meta.aspectRatio,
            cropPositionX: prevState.meta.cropPositionX,
            cropPositionY: prevState.meta.cropPositionY,
            cropWidth: prevState.meta.cropWidth,
            cropHeight: prevState.meta.cropHeight,
            finishingCategoryId: prevState.meta.finishingCategoryId,
            requiredInputs: ['Mount Size', 'Mount Color'],
          },
          inputs: {
            paperType: prevState.inputs.paperType,
            printSize: prevState.inputs.printSize,
            finishingService: prevState.inputs.finishingService,
            mountSize: {
              ...defaultOptions.mountSize,
              options: mountSizes,
              isLoading: false,
              isDisabled: false,
            },
            mountColor: defaultOptions.mountColor,
          },
        }))
      }
    }
  }, [finishingServicesQuery.isInitialLoading])

  useEffect(() => {
    if (form.inputs.frame?.selectedValue === undefined) return
    let frameMats

    if (form.inputs.frameType.selectedValueText === 'Contemporary Frame') {
      frameMats = finishingServicesQuery?.data?.data
        .filter((service) => service.attributes.frameWoodPricing === form.inputs.frame.selectedValueText)
        .map((service) => service.attributes.frameMatName)
    } else if (form.inputs.frameType.selectedValueText === 'Hardwood Frame') {
      frameMats = finishingServicesQuery?.data?.data
        .filter(
          (service) => service.attributes.frameWoodAndProfileName === form.inputs.frame.selectedValueText,
        )
        .map((service) => service.attributes.frameMatName)
    }

    const uniqueFrameMats = [...new Set(frameMats)]

    const sortedUniqueFrames = uniqueFrameMats.sort((a, b) => {
      if (a < b) {
        return -1
      }
      if (a > b) {
        return 1
      }
      return 0
    }, [])

    setForm((prevState) => ({
      meta: {
        subtotal: prevState.meta.subtotal,
        xAspectRatio: prevState.meta.xAspectRatio,
        yAspectRatio: prevState.meta.yAspectRatio,
        aspectRatio: prevState.meta.aspectRatio,
        cropPositionX: prevState.meta.cropPositionX,
        cropPositionY: prevState.meta.cropPositionY,
        cropWidth: prevState.meta.cropWidth,
        cropHeight: prevState.meta.cropHeight,
        finishingCategoryId: prevState.meta.finishingCategoryId,
        requiredInputs: ['Frame Mat', 'Acrylic', 'Frame Stain'],
      },
      inputs: {
        paperType: prevState.inputs.paperType,
        printSize: prevState.inputs.printSize,
        finishingService: prevState.inputs.finishingService,
        frameType: prevState.inputs.frameType,
        frame: prevState.inputs.frame,
        frameMat: {
          ...defaultOptions.frameMat,
          options: sortedUniqueFrames.map((frameMat) => ({
            value: frameMat,
            text: frameMat,
          })),
          isLoading: false,
          isDisabled: false,
        },
        acrylic: defaultOptions.acrylic,
        frameStain: defaultOptions.frameStain,
      },
    }))
  }, [form.inputs.frame?.selectedValue])

  useEffect(() => {
    let acrylics

    if (form.inputs.frameMat?.selectedValue === undefined) return

    if (form.inputs.frameType.selectedValueText === 'Contemporary Frame') {
      acrylics = finishingServicesQuery?.data?.data
        .filter(
          (service) =>
            service.attributes.frameWoodPricing === form.inputs.frame.selectedValueText &&
            service.attributes.frameMatName === form.inputs.frameMat.selectedValueText,
        )
        .map((service) => ({
          value: service.attributes.guid,
          text: service.attributes.frameGlassName,
        }))
    } else if (form.inputs.frameType.selectedValueText === 'Hardwood Frame') {
      acrylics = finishingServicesQuery?.data?.data
        .filter(
          (service) =>
            service.attributes.frameWoodAndProfileName === form.inputs.frame.selectedValueText &&
            service.attributes.frameMatName === form.inputs.frameMat.selectedValueText,
        )
        .map((service) => ({
          value: service.attributes.guid,
          text: service.attributes.frameGlassName,
        }))
    }

    setForm((prevState) => ({
      meta: {
        subtotal: prevState.meta.subtotal,
        xAspectRatio: prevState.meta.xAspectRatio,
        yAspectRatio: prevState.meta.yAspectRatio,
        aspectRatio: prevState.meta.aspectRatio,
        cropPositionX: prevState.meta.cropPositionX,
        cropPositionY: prevState.meta.cropPositionY,
        cropWidth: prevState.meta.cropWidth,
        cropHeight: prevState.meta.cropHeight,
        finishingCategoryId: prevState.meta.finishingCategoryId,
        requiredInputs: ['Acrylic', 'Frame Stain'],
      },
      inputs: {
        paperType: prevState.inputs.paperType,
        printSize: prevState.inputs.printSize,
        finishingService: prevState.inputs.finishingService,
        frameType: prevState.inputs.frameType,
        frame: prevState.inputs.frame,
        frameMat: prevState.inputs.frameMat,
        acrylic: {
          ...defaultOptions.acrylic,
          options: acrylics,
          isLoading: false,
          isDisabled: false,
        },
        frameStain: defaultOptions.frameStain,
      },
    }))
  }, [form.inputs.frameMat?.selectedValue])

  useEffect(() => {
    if (form.inputs.acrylic?.selectedValue === undefined && form.inputs.mountSize?.selectedValue === undefined) return
    if (form.inputs.acrylic?.selectedValue === 'default') {
      setForm((prevState) => ({
        meta: {
          subtotal: prevState.meta.subtotal,
          xAspectRatio: prevState.meta.xAspectRatio,
          yAspectRatio: prevState.meta.yAspectRatio,
          aspectRatio: prevState.meta.aspectRatio,
          cropPositionX: prevState.meta.cropPositionX,
          cropPositionY: prevState.meta.cropPositionY,
          cropWidth: prevState.meta.cropWidth,
          cropHeight: prevState.meta.cropHeight,
          finishingCategoryId: prevState.meta.finishingCategoryId,
          requiredInputs: ['Acrylic', 'Frame Stain'],
        },
        inputs: {
          paperType: prevState.inputs.paperType,
          printSize: prevState.inputs.printSize,
          finishingService: prevState.inputs.finishingService,
          frameType: prevState.inputs.frameType,
          frame: prevState.inputs.frame,
          frameMat: prevState.inputs.frameMat,
          acrylic: prevState.inputs.acrylic,
          frameStain: defaultOptions.frameStain,
        },
      }))
      return
    }

    if (form.inputs.mountSize?.selectedValue === 'default') {
      setForm((prevState) => ({
        meta: {
          subtotal: prevState.meta.subtotal,
          xAspectRatio: prevState.meta.xAspectRatio,
          yAspectRatio: prevState.meta.yAspectRatio,
          aspectRatio: prevState.meta.aspectRatio,
          cropPositionX: prevState.meta.cropPositionX,
          cropPositionY: prevState.meta.cropPositionY,
          cropWidth: prevState.meta.cropWidth,
          cropHeight: prevState.meta.cropHeight,
          finishingCategoryId: prevState.meta.finishingCategoryId,
          requiredInputs: ['Mount Size', 'Mount Color'],
        },
        inputs: {
          paperType: prevState.inputs.paperType,
          printSize: prevState.inputs.printSize,
          finishingService: prevState.inputs.finishingService,
          mountSize: prevState.inputs.mountSize,
          mountColor: defaultOptions.mountColor,
        },
      }))
      return
    }

    let finishingServiceId;
    let error;

    if (form.inputs.acrylic?.selectedValue !== undefined) {
      finishingServiceId = form.inputs.acrylic.selectedValue
      error = 'Please fill Frame Stain'
    } else if (form.inputs.mountSize?.selectedValue !== undefined) {
      finishingServiceId = form.inputs.mountSize.selectedValue
      error = 'Please fill Mount Color'
    }

    setForm((prevState) => ({
      ...prevState,
      meta: {
        subtotal: prevState.meta.subtotal,
        xAspectRatio: prevState.meta.xAspectRatio,
        yAspectRatio: prevState.meta.yAspectRatio,
        aspectRatio: prevState.meta.aspectRatio,
        cropPositionX: prevState.meta.cropPositionX,
        cropPositionY: prevState.meta.cropPositionY,
        cropWidth: prevState.meta.cropWidth,
        cropHeight: prevState.meta.cropHeight,
        finishingCategoryId: prevState.meta.finishingCategoryId,
        finishingServiceId,
        error,
      },
    }))
  }, [form.inputs.acrylic?.selectedValue, form.inputs.mountSize?.selectedValue])

  const finishingDetailsOptionsQuery = useQuery({
    queryKey: ['finishingDetailsOptions', form.meta.finishingServiceId],
    queryFn: () =>
      httpClient.get(`products/${form.meta.finishingServiceId}/finishing_details`),
    enabled: !!form.meta.finishingServiceId,
    staleTime: Infinity,
    cacheTime: 0,
  })

  useEffect(() => {
    if (finishingDetailsOptionsQuery.isError) {
      // TODO: handle error
    } else if (finishingDetailsOptionsQuery.isInitialLoading) {
      if (form.inputs.finishingService.selectedValueText === 'Framing') {
        setForm((prevState) => ({
          meta: {
            subtotal: prevState.meta.subtotal,
            xAspectRatio: prevState.meta.xAspectRatio,
            yAspectRatio: prevState.meta.yAspectRatio,
            aspectRatio: prevState.meta.aspectRatio,
            cropPositionX: prevState.meta.cropPositionX,
            cropPositionY: prevState.meta.cropPositionY,
            cropWidth: prevState.meta.cropWidth,
            cropHeight: prevState.meta.cropHeight,
            finishingCategoryId: prevState.meta.finishingCategoryId,
            finishingServiceId: prevState.meta.finishingServiceId,
            requiredInputs: ['Frame Stain'],
          },
          inputs: {
            paperType: prevState.inputs.paperType,
            printSize: prevState.inputs.printSize,
            finishingService: prevState.inputs.finishingService,
            frameType: prevState.inputs.frameType,
            frame: prevState.inputs.frame,
            frameMat: prevState.inputs.frameMat,
            acrylic: prevState.inputs.acrylic,
            frameStain: {
              ...defaultOptions.frameStain,
              isLoading: true,
              isDisabled: true,
            },
          },
        }))
      } else if (form.inputs.finishingService.selectedValueText === 'Mounting') {
        setForm((prevState) => ({
          meta: {
            subtotal: prevState.meta.subtotal,
            xAspectRatio: prevState.meta.xAspectRatio,
            yAspectRatio: prevState.meta.yAspectRatio,
            aspectRatio: prevState.meta.aspectRatio,
            cropPositionX: prevState.meta.cropPositionX,
            cropPositionY: prevState.meta.cropPositionY,
            cropWidth: prevState.meta.cropWidth,
            cropHeight: prevState.meta.cropHeight,
            finishingCategoryId: prevState.meta.finishingCategoryId,
            finishingServiceId: prevState.meta.finishingServiceId,
            requiredInputs: ['Mount Color'],
          },
          inputs: {
            paperType: prevState.inputs.paperType,
            printSize: prevState.inputs.printSize,
            finishingService: prevState.inputs.finishingService,
            mountSize: prevState.inputs.mountSize,
            mountColor: {
              ...defaultOptions.mountColor,
              isLoading: true,
              isDisabled: true,
            },
          },
        }))
      }
    } else if (finishingDetailsOptionsQuery.isSuccess) {
      if (form.inputs.finishingService.selectedValueText === 'Framing') {
        const frameStains = finishingDetailsOptionsQuery.data.data.map((detail) => ({
          value: detail.attributes.guid,
          text: detail.attributes.name,
        }))

        setForm((prevState) => ({
          meta: {
            subtotal: prevState.meta.subtotal,
            xAspectRatio: prevState.meta.xAspectRatio,
            yAspectRatio: prevState.meta.yAspectRatio,
            aspectRatio: prevState.meta.aspectRatio,
            cropPositionX: prevState.meta.cropPositionX,
            cropPositionY: prevState.meta.cropPositionY,
            cropWidth: prevState.meta.cropWidth,
            cropHeight: prevState.meta.cropHeight,
            finishingCategoryId: prevState.meta.finishingCategoryId,
            finishingServiceId: prevState.meta.finishingServiceId,
            requiredInputs: ['Frame Stain'],
          },
          inputs: {
            paperType: prevState.inputs.paperType,
            printSize: prevState.inputs.printSize,
            finishingService: prevState.inputs.finishingService,
            frameType: prevState.inputs.frameType,
            frame: prevState.inputs.frame,
            frameMat: prevState.inputs.frameMat,
            acrylic: prevState.inputs.acrylic,
            frameStain: {
              ...defaultOptions.frameStain,
              options: frameStains,
              isLoading: false,
              isDisabled: false,
            },
          },
        }))
      } else if (form.inputs.finishingService.selectedValueText === 'Mounting') {
        const mountColors = finishingDetailsOptionsQuery.data.data.map((detail) => ({
          value: detail.attributes.guid,
          text: detail.attributes.name,
        }))

        setForm((prevState) => ({
          meta: {
            subtotal: prevState.meta.subtotal,
            xAspectRatio: prevState.meta.xAspectRatio,
            yAspectRatio: prevState.meta.yAspectRatio,
            aspectRatio: prevState.meta.aspectRatio,
            cropPositionX: prevState.meta.cropPositionX,
            cropPositionY: prevState.meta.cropPositionY,
            cropWidth: prevState.meta.cropWidth,
            cropHeight: prevState.meta.cropHeight,
            finishingCategoryId: prevState.meta.finishingCategoryId,
            finishingServiceId: prevState.meta.finishingServiceId,
            requiredInputs: ['Mount Color'],
          },
          inputs: {
            paperType: prevState.inputs.paperType,
            printSize: prevState.inputs.printSize,
            finishingService: prevState.inputs.finishingService,
            mountSize: prevState.inputs.mountSize,
            mountColor: {
              ...defaultOptions.mountColor,
              options: mountColors,
              isLoading: false,
              isDisabled: false,
            },
          },
        }))
      }
    }
  }, [finishingDetailsOptionsQuery.isInitialLoading])

  useEffect(() => {
    if (form.inputs.frameStain?.selectedValue === undefined && form.inputs.mountColor?.selectedValue === undefined) return

    const finishingDetailId = form.inputs.frameStain?.selectedValue || form.inputs.mountColor.selectedValue

    setForm((prevState) => ({
      meta: {
        subtotal: prevState.meta.subtotal,
        xAspectRatio: prevState.meta.xAspectRatio,
        yAspectRatio: prevState.meta.yAspectRatio,
        aspectRatio: prevState.meta.aspectRatio,
        cropPositionX: prevState.meta.cropPositionX,
        cropPositionY: prevState.meta.cropPositionY,
        cropWidth: prevState.meta.cropWidth,
        cropHeight: prevState.meta.cropHeight,
        finishingCategoryId: prevState.meta.finishingCategoryId,
        finishingServiceId: prevState.meta.finishingServiceId,
        finishingDetailId,
        requiredInputs: [],
      },
      inputs: prevState.inputs,
    }))
  }, [form.inputs.frameStain?.selectedValue, form.inputs.mountColor?.selectedValue])

  useEffect(() => {
    if (form.inputs.matSize?.selectedValue === undefined) return

    const finishingServiceId = form.inputs.matSize.selectedValue

    setForm((prevState) => ({
      meta: {
        ...prevState.meta,
        finishingServiceId,
        requiredInputs: [],
      },
      inputs: prevState.inputs,
    }))
  }, [form.inputs.matSize?.selectedValue])

  useEffect(() => {
    if (form.meta.finishingDetailId) {
      window.history.pushState(
        '',
        '',
        `/product-select?selectedPhoto=${selectedPhoto.attributes.guid}&finishingService=${form.meta.finishingCategoryId}&finishingDetail=${form.meta.finishingDetailId}`,
      )
    }
  }, [form.meta.finishingDetailId])

  const handleReviewOrder = async () => {
    if (form.meta.requiredInputs?.length > 0) {
      setModalIsOpen(true)
      return
    }
    if (selectedPhoto === '') return

    const body = {
      line_items: [
        {
          imageName: selectedPhoto.attributes.name,
          quantity: parseInt(quantityRef.current.value, 10),
          print_size: form.inputs.printSize.selectedValue,
          finishing_service: form.meta.finishingServiceId,
          finishing_service_detail: form.meta.finishingDetailId,
          print_border: 'None',
          notes: notesRef.current.value,
          source_photograph_guid: selectedPhoto.attributes.guid,
          photograph: {
            source_photograph_guid: selectedPhoto.attributes.guid,
            crop_x: form.meta.cropPositionX,
            crop_y: form.meta.cropPositionY,
            crop_h: form.meta.cropHeight,
            crop_w: form.meta.cropWidth,
            photo_meta: {
              x: form.meta.cropPositionX,
              y: form.meta.cropPositionY,
              width: form.meta.cropWidth,
              height: form.meta.cropHeight,
              selectedPaper: form.inputs.paperType.selectedValueText,
              unit: 'px',
              aspect: form.meta.aspectRatio,
              x_aspect: form.meta.xAspectRatio,
              y_aspect: form.meta.yAspectRatio,
              locked: false,
              keepSelection: true,
              border: 'none',
              imageGuid: selectedPhoto.attributes.guid,
              imageRef: selectedPhoto.attributes.guid,
              ref: selectedPhoto.attributes.guid,
            },
          },
        },
      ],
    }

    const data = await httpClient.post(`lab_orders/${id}/line_items`, body)

    if (data.data) {
      setPhotos((prevState) => prevState.filter((photo) => photo.id !== selectedPhoto.id))
      setSelectedPhoto('')
      resetSelectedOptions()
    } else {
      // TODO: handle error
    }
  }

  return (
    <div className="w-layout-grid grid-print-order">
      <Modal isOpen={ModalIsOpen} onClose={() => setModalIsOpen(false)}>
        <h5>app.musealab.com</h5>
        <p>{form.meta.reviewOrderError}</p>
      </Modal>
      <div className="sidebar-product-selection white">
        <div className="controls-block-container">
          <div className="controls-block-grid top">
            <Link to="/" data-tippy-content="Back to Home" className="div-block-169-copy ghost">
              <div className="icon small w-embed">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                  <rect width="256" height="256" fill="none" />
                  <path d="M218.76367,103.7002,138.75684,30.96436a15.93657,15.93657,0,0,0-21.52637.00146L37.2373,103.69971A16.03108,16.03108,0,0,0,32,115.53857l0,92.09522a16.47275,16.47275,0,0,0,4.01066,10.96174A15.91729,15.91729,0,0,0,48.002,223.999H95.96484a8,8,0,0,0,8-8V167.9917a8,8,0,0,1,8-8h32a8,8,0,0,1,8,8V215.999a8,8,0,0,0,8,8h48.05731a15.40625,15.40625,0,0,0,7.53406-1.85584A16.08415,16.08415,0,0,0,224,207.999v-92.46A16.03567,16.03567,0,0,0,218.76367,103.7002Z" />
                </svg>
              </div>
            </Link>
            <button
              data-tippy-content="Reset All"
              type="button"
              className="div-block-169-copy ghost"
              onClick={() => resetSelectedOptions()}
            >
              <div className="icon small w-embed">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                  <rect width="256" height="256" fill="none" />
                  <polyline
                    points="79.833 99.716 31.833 99.716 31.833 51.716"
                    fill="none"
                    stroke="currentcolor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                  <path
                    d="M190.2254,65.7746a88,88,0,0,0-124.4508,0L31.83348,99.71573"
                    fill="none"
                    stroke="currentcolor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                  <polyline
                    points="176.167 156.284 224.167 156.284 224.167 204.284"
                    fill="none"
                    stroke="currentcolor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                  <path
                    d="M65.7746,190.2254a88,88,0,0,0,124.4508,0l33.94112-33.94113"
                    fill="none"
                    stroke="currentcolor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                </svg>
              </div>
            </button>
            <a href={`/print-order/upload-more/${id}`} className="button-2 w-inline-block">
              <div className="icon right-margin w-embed">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                  <rect width="256" height="256" fill="none" />
                  <path d="M128,23.99805a104,104,0,1,0,104,104A104.12041,104.12041,0,0,0,128,23.99805Zm40,112H136v32a8,8,0,1,1-16,0v-32H88a8,8,0,0,1,0-16h32v-32a8,8,0,0,1,16,0v32h32a8,8,0,0,1,0,16Z" />
                </svg>
              </div>
              <div>Upload More</div>
            </a>
          </div>
        </div>
        <div className="print-order-sidebar grid">
          <div
            className="accordion-item-block"
            style={{ gridTemplateColumns: 'auto auto auto auto', gridTemplateGaps: '15px' }}
          >
            <div className="input-container quantity">
              <div className="label-container">
                <div className="div-block-150">
                  <div className="sub-label-form">Quantity</div>
                  <div className="icon mini margin-left opacity70 w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none" />
                      <path d="M128.00146,23.99963a104,104,0,1,0,104,104A104.11791,104.11791,0,0,0,128.00146,23.99963ZM128.002,192a12,12,0,1,1,12-12A12,12,0,0,1,128.002,192Zm7.99951-48.891v.89551a8,8,0,1,1-16,0v-8a8.0004,8.0004,0,0,1,8-8,20,20,0,1,0-20-20,8,8,0,0,1-16,0,36,36,0,1,1,44,35.10449Z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="form-block-input w-form">
                <div className="input-wrapper">
                  <input
                    ref={quantityRef}
                    type="number"
                    maxLength="256"
                    name="field-4"
                    data-name="Field 4"
                    id="data-jscolor"
                    defaultValue="1"
                    className="text-field-2 data-jscolor w-input"
                  />
                </div>
              </div>
            </div>
            {Object.keys(form.inputs).map((key) => (
              <div
                key={key}
                style={form.inputs[key].style}
              >
                <div className="input-container paper-type">
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">{form.inputs[key].label}</div>
                      <div className="icon mini margin-left opacity70 w-embed">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentcolor"
                          viewBox="0 0 256 256"
                        >
                          <rect width="256" height="256" fill="none" />
                          <path d="M128.00146,23.99963a104,104,0,1,0,104,104A104.11791,104.11791,0,0,0,128.00146,23.99963ZM128.002,192a12,12,0,1,1,12-12A12,12,0,0,1,128.002,192Zm7.99951-48.891v.89551a8,8,0,1,1-16,0v-8a8.0004,8.0004,0,0,1,8-8,20,20,0,1,0-20-20,8,8,0,0,1-16,0,36,36,0,1,1,44,35.10449Z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form className="form-input select-field">
                      <div className="select-field-wrapper">
                        <select
                          id="my-field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                          onChange={(e) => {
                            setForm((prevState) => {
                              const newState = { ...prevState }
                              newState.inputs[key].selectedValue = e.target.value
                              newState.inputs[key].selectedValueText = e.target.options[e.target.selectedIndex].text
                              newState.inputs[key].selectedValuePrice = form.inputs[key].options.find(
                                (option) => option.value.toString() === e.target.value,
                              )?.price
                              if (form.inputs[key].options[0].xAspectRatio && form.inputs[key].options[0].yAspectRatio) {
                                newState.meta.xAspectRatio = form.inputs[key].options.find(
                                  (option) => option.value.toString() === e.target.value,
                                )?.xAspectRatio
                                newState.meta.yAspectRatio = form.inputs[key].options.find(
                                  (option) => option.value.toString() === e.target.value,
                                )?.yAspectRatio
                              }
                              return newState
                            })
                          }}
                          value={form.inputs[key].selectedValue || ''}
                        >
                          {form.inputs[key].isLoading === true ? (
                            <option value="">Loading...</option>
                          ) : (
                            <option value="default">Select</option>
                          )}
                          {
                            form.inputs[key].options.length > 0 &&
                              form.inputs[key].options.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.text}
                                </option>
                              ))
                          }
                        </select>

                        <div className={`div-block-63 ${styles['chevron-icon']}`}>
                          <img src={ChevronIcon} loading="lazy" alt="" className="img-icon" />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="bottom-controls-product-selection-sidebar">
          <div className="controls-block-grid">
            <div>Subtotal:</div>
            <div className="text-block-32">$ {subtotal}</div>
          </div>
          <button onClick={handleReviewOrder} type="button" className="container-cart-button w-inline-block" style={{ display: 'grid', alignItems: 'stretch', padding: '0' }}>
            <div className="div-block-186">
              <div className="icon right-margin w-embed">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                  <rect width="256" height="256" fill="none" />
                  <path d="M223.87109,65.43115l-12.15722,66.86231A23.98711,23.98711,0,0,1,188.10156,152H72.13135l4.36377,24H184a24.01161,24.01161,0,1,1-22.624,16H102.624a24.00309,24.00309,0,1,1-40.85986-7.57666L34.05078,32H16a8,8,0,0,1,0-16H34.05078A15.9918,15.9918,0,0,1,49.793,29.13818L54.67676,56H216a8.00076,8.00076,0,0,1,7.87109,9.43115Z" />
                </svg>
              </div>
              <div>Add To Cart</div>
            </div>
            <div className="div-block-187">
              <div className="div-block-188">
                <div>3</div>
              </div>
            </div>
          </button>
          <a href={`/lab-orders/${id}/summary`} className="button-2 w-inline-block">
            <div className="icon right-margin w-embed">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                <rect width="256" height="256" fill="none" />
                <path d="M247.31055,124.75061c-.35157-.79-8.81934-19.57617-27.65332-38.41113C194.57324,61.25256,162.87793,47.99182,128,47.99182S61.42676,61.25256,36.34277,86.33948c-18.834,18.835-27.30175,37.62109-27.65332,38.41113a8.00282,8.00282,0,0,0,0,6.49805c.35157.791,8.82032,19.57226,27.6543,38.40429C61.42773,194.734,93.12207,207.99182,128,207.99182S194.57227,194.734,219.65625,169.653c18.834-18.832,27.30273-37.61328,27.6543-38.40429A8.00282,8.00282,0,0,0,247.31055,124.75061ZM128,92.00061a36,36,0,1,1-36,36A36.04061,36.04061,0,0,1,128,92.00061Z" />
              </svg>
            </div>
            <div>Review Order</div>
          </a>
        </div>
      </div>
      <div>
        <div className="product-selection-picture-container">
          {/* ! TODO: REMOVE code block below */}
          {/* <pre style={{ minHeight: '100vh' }}>{JSON.stringify(form, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(form.meta.reviewOrderError, null, 2)}</pre> */}
          <div className="product-selection-main-picture-container" style={{ overflow: 'scroll' }}>
            {photosLoadingMessage && <Loading message={photosLoadingMessage} />}
            { !photosLoadingMessage && selectedPhoto.attributes &&
              (
              <div className="main-picture-grid">
                <div className="picture-product-selection">

                  <Cropper
                    src={selectedPhoto?.attributes?.presignedPhotoUrl}
                    style={{ maxHeight: '550px', width: '100%' }}
                    sizes="(max-width: 479px) 247.5px, (max-width: 767px) 52vw, (max-width: 1439px) 43vw, 44vw"
                    ref={cropperRef}
                    // Cropper.js options
                    guides
                    crop={onCrop}
                    viewMode={1}
                    dragMode="move"
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    responsive
                    autoCropArea={1}
                    checkCrossOrigin={false}
                    moveable={false}
                    autoCrop={false}
                    zoomable={false}
                    cropBoxMovable={false}
                    // cropBoxResizable={true}
                    background={false}
                  />
                  <div className="prem-frame-wrap">
                    <div className="prem-frame" />
                  </div>
                </div>
                <div className="img-controls-grid">
                  <button type="button" className="rotate-button" onClick={handleCropBoxRotate}>
                    <div className="icon small w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none" />
                        <polyline
                          points="176.167 99.716 224.167 99.716 224.167 51.716"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                        <path
                          d="M190.2254,190.2254a88,88,0,1,1,0-124.4508l33.94112,33.94113"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
              )
            }
          </div>
          <div className={styles['side-controls-product-selection']}>
            <div className="w-layout-grid grid-44">
              {/* eslint-disable-next-line arrow-body-style */}
              <SortImages
                handleImage={(newImages) => handleChangePhotoOrder(newImages)}
                images={photos}
              />
              <div className="rotate-button">
                <div className="icon small w-embed">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                    <rect width="256" height="256" fill="none" />
                    <path
                      d="M43.99951,143.27273V52a8,8,0,0,1,8-8h152a8,8,0,0,1,8,8V204a8,8,0,0,1-8,8h-68.3641"
                      fill="none"
                      stroke="currentcolor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="24"
                    />
                    <polyline
                      points="128 152 64 216 32 184"
                      fill="none"
                      stroke="currentcolor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="24"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className={styles['bottom-controls-product-selection']}>
            {
              !photosLoadingMessage && photos.length > 0 &&
              <div style={{ display: selectedPhoto === '' ? 'block' : 'none', width: '40px', height: '40px', marginLeft: '150px', transform: 'rotate(-90deg)' }} ref={arrowContainer}> </div>
            }
            <div className={styles['bottom-controls-product-selection-grid']}>
              {!photosLoadingMessage && photos.length > 0 &&
                photos.map((photo) => (
                  <div
                    key={photo.id}
                    className="picture-element-product-selection"
                    onClick={() => handleSelectedPhoto(photo)}
                    role="button"
                    style={{ position: 'relative' }}
                  >
                    {
                      photo.attributes.inCart && (
                        <div className="w-layout-grid top-buttons-image-element">
                          <div className="cart-symbol">
                            <div className="icon small x2 w-embed">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentcolor"
                                viewBox="0 0 256 256"
                              >
                                <rect width="256" height="256" fill="none" />
                                <path d="M96,216a16,16,0,1,1-16-16A16.00016,16.00016,0,0,1,96,216Zm88-16a16,16,0,1,0,16,16A16.00016,16.00016,0,0,0,184,200ZM228.10059,67.18262A7.99866,7.99866,0,0,0,221.71387,64H48.32031L40.207,35.604A16.06984,16.06984,0,0,0,24.82227,24H8A8,8,0,0,0,8,40H24.82227l9.73046,34.05469c.02637.09863.05372.19677.084.29443l26.35547,92.24365A24.10087,24.10087,0,0,0,84.06836,184h95.86328a24.101,24.101,0,0,0,23.07617-17.40674l26.39844-92.39551A8.00023,8.00023,0,0,0,228.10059,67.18262Z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      )
                    }
                    <img
                      src={photo.attributes.smallestPhoto}
                      loading="lazy"
                      sizes="90px"
                      alt={photo.attributes.photoFileName}
                      className="image-thumbnail"
                      style={{ margin: '8px 0' }}
                    />
                    <div className="info-container-mini">
                      <div className="icon smaller right-margin supermini w-embed">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentcolor"
                          viewBox="0 0 256 256"
                        >
                          <rect width="256" height="256" fill="none" />
                          <path
                            d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                            fill="none"
                            stroke="currentcolor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="24"
                          />
                        </svg>
                      </div>
                      <div>{photo.attributes.photoFileName}</div>
                    </div>
                    <div
                      className="w-layout-grid top-buttons-image-element"
                      style={{ display: 'none' }}
                    >
                      <div className="cart-symbol">
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none" />
                            <path d="M96,216a16,16,0,1,1-16-16A16.00016,16.00016,0,0,1,96,216Zm88-16a16,16,0,1,0,16,16A16.00016,16.00016,0,0,0,184,200ZM228.10059,67.18262A7.99866,7.99866,0,0,0,221.71387,64H48.32031L40.207,35.604A16.06984,16.06984,0,0,0,24.82227,24H8A8,8,0,0,0,8,40H24.82227l9.73046,34.05469c.02637.09863.05372.19677.084.29443l26.35547,92.24365A24.10087,24.10087,0,0,0,84.06836,184h95.86328a24.101,24.101,0,0,0,23.07617-17.40674l26.39844-92.39551A8.00023,8.00023,0,0,0,228.10059,67.18262Z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductSelection
